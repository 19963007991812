const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:5001';

async function generateSpeech(text) {
    const response = await fetch(`${API_BASE_URL}/tts`, {  // Start of the fetch options
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ text }),  // Your text is part of the request body here
    });

    if (!response.ok) {
        throw new Error('Speech generation failed');
    }

    const blob = await response.blob();
    const url = URL.createObjectURL(blob);
    return url;
}

export default generateSpeech;
