import BaseScene from "./BaseScene"; // Adjust the path as necessary
import ccBackground from "../assets/backgrounds/CreativeCarnival.jpg";
// import backButton from "../assets/buttons/BackButton.png";
import navButton from "../assets/buttons/NavButton.png";
import wordBankButton from "../assets/buttons/WordBankButton.png"
import adjectivesButton from "../assets/buttons/AdjectivesButton.png";
import metaphorsButton from "../assets/buttons/MetaphorsButton.png";
import punctuationsButton from "../assets/buttons/PunctuationsButton.png";
import talkTagsButton from "../assets/buttons/TalkTagsButton.png";
import similesButton from "../assets/buttons/SimilesButton.png";
import idiomsButton from "../assets/buttons/IdiomsButton.png";
import proverbsButton from "../assets/buttons/ProverbsButton.png";
import collectionsButton from "../assets/buttons/CollectionsButton.png";




class CreativeCarnivalScene extends BaseScene {
    constructor() {
        super('CreativeCarnivalScene');
        this.currentTopicIndex = 0; // Start at the first topic
        this.currentButton = null; // To keep track of the current button displayed
        this.lastDirection = 1; // Default direction (down)
    }

    init() {
        this.registry.set('debug', false);
        const isPortrait = this.sys.game.config.width < this.sys.game.config.height;
        if (isPortrait) {
            this.registry.set('isPortrait', true);
        }
    }

    preload() {
        super.preloadCommon();
        this.load.image('ccBackgroundImage', ccBackground); // Note the key 'backgroundImage' here
        this.load.image('navButton', navButton);
        this.load.image('adjectivesButton', adjectivesButton);
        this.load.image('wordBankButton', wordBankButton);
        this.load.image('metaphorsButton', metaphorsButton);
        this.load.image('punctuationsButton', punctuationsButton);
        this.load.image('talkTagsButton', talkTagsButton);
        this.load.image('similesButton', similesButton);
        this.load.image('idiomsButton', idiomsButton);
        this.load.image('proverbsButton', proverbsButton);
        this.load.image('collectionsButton', collectionsButton);

    }

    create() {

        const debug = this.registry.get('debug');
        const isPortrait = this.registry.get('isPortrait');
        if (debug) {
            console.log('CreativeCarnivalScene is active.');
        }

        this.setupPageTemplate(isPortrait, debug, 'Creative Carnival', 'ccBackgroundImage', 'MenuScene' );

        // const ccBg = this.setBackground('ccBackgroundImage'); // Use the key you've defined in preload
        // this.backgroundOverlay()
        const userData = this.game.registry.get('userData');
        const userRoles =this.game.registry.get('userRoles') || [];
        const baseFontSize = this.getBaseFontSize() * 2;
        const strokeThickness = this.sys.game.config.width * 0.006;

        const menuButtonXPosition = this.sys.game.config.width * 0.75;
        const menuButtonYupPosition = this.sys.game.config.height * 0.2;
        const menuButtonYdownPosition = this.sys.game.config.height * 0.8;
        // Rotated and functional nav buttons
        const navButtonUp = this.createButton(menuButtonXPosition, menuButtonYupPosition, 'navButton', null, () => this.changeTopic(1));
        navButtonUp.angle = -90;  // Rotates the button to face North

        const navButtonDown = this.createButton(menuButtonXPosition, menuButtonYdownPosition, 'navButton', null, () => this.changeTopic(-1));
        navButtonDown.angle = 90;  // Rotates the button to face South

        this.currentTopic = [

            {
                key: 'wordBank',
                button: 'wordBankButton',
                reactComponent: 'generateWordMeaningQuestion',
                parentScene: 'CreativeCarnivalScene',
                possibleQuestionScenes: ['Question1Scene'],
                available: true
            },
            {
                key: 'adjectives',
                button: 'adjectivesButton',
                reactComponent: 'generateAdjectiveQuestion',
                parentScene: 'CreativeCarnivalScene',
                possibleQuestionScenes: ['Question1Scene'],
                available: true
            },
            {
                key: 'metaphors',
                button: 'metaphorsButton',
                reactComponent: 'generateMetaphorQuestion',
                parentScene: 'CreativeCarnivalScene',
                possibleQuestionScenes: ['Question1Scene'],
                available: true
            },
            {
                key: 'punctuationsButton',
                button: 'punctuationsButton',
                reactComponent: 'generatePunctuationQuestion',
                parentScene: 'CreativeCarnivalScene',
                possibleQuestionScenes: ['Question1Scene'],
                available: true
            },
            {
                key: 'talkTags',
                button: 'talkTagsButton',
                reactComponent: 'generateSpeechWordsQuestion',
                parentScene: 'CreativeCarnivalScene',
                possibleQuestionScenes: ['Question1Scene'],
                available: true
            },
            {
                key: 'similes',
                button: 'similesButton',
                reactComponent: 'generateSpeechWordsQuestion',
                parentScene: 'CreativeCarnivalScene',
                possibleQuestionScenes: ['Question1Scene'],
                available: true
            },
            {
                key: 'punctuationsButton',
                button: 'punctuationsButton',
                reactComponent: 'generatePunctuationQuestion',
                parentScene: 'CreativeCarnivalScene',
                possibleQuestionScenes: ['Question1Scene'],
                available: true
            },
            {
                key: 'talkTags',
                button: 'talkTagsButton',
                reactComponent: 'generateSpeechWordsQuestion',
                parentScene: 'CreativeCarnivalScene',
                possibleQuestionScenes: ['Question1Scene'],
                available: true
            },
            {
                key: 'similes',
                button: 'similesButton',
                reactComponent: 'generateSimileQuestion',
                parentScene: 'CreativeCarnivalScene',
                possibleQuestionScenes: ['Question1Scene'],
                available: true
            },
            {
                key: 'idioms',
                button: 'idiomsButton',
                reactComponent: 'generateIdiomQuestion',
                parentScene: 'CreativeCarnivalScene',
                possibleQuestionScenes: ['Question1Scene'],
                available: true
            },
            {
                key: 'proverbs',
                button: 'proverbsButton',
                reactComponent: 'generateProverbQuestion',
                parentScene: 'CreativeCarnivalScene',
                possibleQuestionScenes: ['Question1Scene'],
                available: true
            },
            {
                key: 'collections',
                button: 'collectionsButton',
                reactComponent: 'generateCollectiveQuestion',
                parentScene: 'CreativeCarnivalScene',
                possibleQuestionScenes: ['Question1Scene'],
                available: true
            },
        ];

        this.showCurrentButton(this.lastDirection);

        // Set up key listeners
        this.setupKeyboardInputs(
            () => this.changeTopic(1),
            () => this.changeTopic(-1)
        );


    }
}

export default CreativeCarnivalScene;
