import BaseScene from "./BaseScene";
import multiplicationBackground from "../assets/backgrounds/Multiplication.jpg";
import nextButton from "../assets/buttons/NavButton.png";

class MultiplicationScene extends BaseScene {
    constructor() {
        super('MultiplicationScene');
        this.questionData = null;
        this.buttonStates = {}; // Object to track the state of each button
    }

    init(data) {
        // Now you have access to `data`
        // this.registry.set('debug', true);
        this.registry.set('debug', false);
        const isPortrait = this.sys.game.config.width < this.sys.game.config.height;
        if (isPortrait) {
            this.registry.set('isPortrait', true);
        }
        this.questionData = data.questionData;
        this.questionsList = this.registry.get('questions');
        this.userData = this.registry.get('userData');
        this.topicData = data.topic;
        // Make sure `topic` is being passed correctly when starting the scene
        this.points = 0;
        this.correctCount = 0;
        this.currentQuestionIndex = 0;
        this.startTime = 10;
        this.multiplier = 1;
        this.bestScoreKey = this.questionsList[0].key;
        if (this.userData && this.bestScoreKeyinthis.userData) {
            this.bestScore = this.userData[this.bestScoreKey]?.bestScore || 0;
        } else {
            this.bestScore = 0;
        }
    }

    preload() {
        this.load.image('multiplicationBackground', multiplicationBackground);
        this.load.image('nextButton', nextButton);
    }

    create() {
        const debug = this.registry.get('debug');

        this.setBackground('multiplicationBackground');
        this.backgroundOverlay();
        const isPortrait = this.sys.game.config.width < this.sys.game.config.height;

        const baseFontSize = parseFloat(this.responsiveFontSize(10, 0.03).replace('px', ''));
        const strokeThickness = this.sys.game.config.width * 0.006;

        // Page info
        this.pageBar('Multiplication');
        this.page1(debug, isPortrait, baseFontSize, strokeThickness);


    }

    page1(debug, isPortrait, baseFontSize, strokeThickness) {
        const navButtonY = isPortrait ? this.sys.game.config.height * 0.8 : this.sys.game.config.height * 0.8;
        const navButtonX = isPortrait ? this.sys.game.config.width * 0.8 : this.sys.game.config.width * 0.8;
        this.page1Group = this.add.group();

        this.createButton(this.sys.game.config.width * 0.9, this.sys.game.config.height * 0.1, 'backButton', null, () => this.scene.start('MenuScene'));


        this.page2Button = this.createButton(navButtonX, navButtonY, 'nextButton', null, () => {
            console.log('page2 button clicked');
        }, 1, true);
        this.page1Group.add(this.page2Button);

        const tables = [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

        const rowTopY = this.sys.game.config.height * 0.25;
        const col1x = this.sys.game.config.width * 0.3;
        const col2x = this.sys.game.config.width * 0.5; // Position for best time display

        for (let i = 0; i < tables.length; i++) {
            const addRowHeight = i * this.sys.game.config.height * 0.05;
            const rowY = rowTopY + addRowHeight;
            const gradientWidth = isPortrait ? this.sys.game.config.width * 0.1 : this.sys.game.config.width * 0.1;
            const gradientHeight = isPortrait ? this.sys.game.config.height * 0.05 : this.sys.game.config.height * 0.05;

            const gradient = this.createGradientBackground(col1x, rowY + 8, gradientWidth, gradientHeight, 0xFBBC05, 0.8);
            const text = this.add.text(col1x, rowY - 10, tables[i], {
                fontSize: `${baseFontSize}px`,
                color: '#f78737',
                stroke: '#000000',
                strokeThickness: strokeThickness,
                align: 'center'
            }).setDepth(1000);

            this.page1Group.add(gradient);
            this.page1Group.add(text);

            // Retrieve and display the best time for this table
            const bestTimeKey = `bestTime_${tables[i]}`;
            const bestTime = localStorage.getItem(bestTimeKey) || '-';
            const bestTimeText = this.add.text(col2x, rowY - 10, `Best Time: ${bestTime} s`, {
                fontFamily: 'gaegu',
                fontSize: `${baseFontSize}px`,
                color: '#f78737',
                stroke: '#000000',
                strokeThickness: strokeThickness,
                align: 'center'
            }).setDepth(1000);

            this.page1Group.add(bestTimeText);

            // Initialize the button state to 'off'
            this.buttonStates[tables[i]] = false;

            // Create the radio button
            const radioButton = this.add.image(col1x + 100, rowY + 5, 'offRadioButton').setInteractive().setDepth(1000); // Adjust position
            const originalHeight = radioButton.height;
            const buttonScale = gradientHeight / originalHeight;
            radioButton.setScale(buttonScale);

            // Toggle the button on click
            radioButton.on('pointerdown', () => {
                this.buttonStates[tables[i]] = !this.buttonStates[tables[i]];
                radioButton.setTexture(this.buttonStates[tables[i]] ? 'onRadioButton' : 'offRadioButton');
                if (debug) {
                    console.log('Button', tables[i], 'is toggled');
                }

                // Store the selected table in registry for later use
                this.registry.set('selectedTable', tables[i]);

                // Check which buttons are on
                this.checkButtonStates(debug, isPortrait, baseFontSize, strokeThickness);
            });

            this.page1Group.add(radioButton);
        }
    }


    checkButtonStates(debug, isPortrait, baseFontSize, strokeThickness) {
        const onButtons = Object.keys(this.buttonStates).filter(key =>this.buttonStates[key]);
        console.log('Buttons that are on:', onButtons);
        if (onButtons.length == 1) {
            this.page2Button.setInteractive(); // Make it interactive
            this.page2Button.setAlpha(1);
            this.page2Button.on('pointerdown', () => {
                console.log('page2 button clicked');
                this.page1Group.clear(true,true);
                this.page2(debug, isPortrait, baseFontSize, strokeThickness, onButtons);
            });
        } else {
            this.page2Button.disableInteractive(); // Disable interaction
            this.page2Button.setAlpha(0.5); // Optionally, make it semi-transparent to indicate it's unavailable
            this.page2Button.off('pointerdown');
        }
    }

    page2(debug, isPortrait, baseFontSize, strokeThickness, onButtons) {

        this.page2Group = this.add.group();

        const navButtonY = isPortrait ? this.sys.game.config.height * 0.8 : this.sys.game.config.height * 0.8;
        const navButtonX =  isPortrait ? this.sys.game.config.width * 0.8 : this.sys.game.config.width * 0.8;

        this.page3Button = this.createButton(navButtonX, navButtonY, 'nextButton', null, () => {
            if (debug) {
                console.log('page3 button clicked');
            };
            this.clearTablesAnswers();
            this.readyCountdown(debug, isPortrait, baseFontSize, strokeThickness, onButtons)

        }, 1);
        this.page2Group.add(this.page3Button);

        const headingX = this.sys.game.config.width /2;
        const headingY = this.sys.game.config.height * 0.2;

        const rowTopY = this.sys.game.config.height * 0.25;
        const col1x = this.sys.game.config.width * 0.4;
        const col2x = this.sys.game.config.width * 0.4;


        if (debug) {
            console.log('Page 2')
            console.log(onButtons)
        }

        if (onButtons.length == 1) {
            const selectedTable = parseInt(onButtons[0], 10);
            const heading = `${selectedTable} Times Tables`;
            const text = this.createAnimatedText(headingX, headingY, heading,{
                fontFamily: 'gaegu',
                fontSize: `${baseFontSize}px`,
                color: '#f78737',
                stroke: '#000000',
                strokeThickness: strokeThickness,
                align: 'center'
            }).setDepth(1000);
            this.page2Group.add(text);

            for (let i = 1; i <= 12; i++) {
                const addRowHeight = (i - 1) * this.sys.game.config.height * 0.05;
                // Calculate row height
                const rowY = rowTopY + addRowHeight;

                const gradientWidth = isPortrait ? this.sys.game.config.width * 0.1 : this.sys.game.config.width * 0.15;
                const gradientHeight = isPortrait ? this.sys.game.config.height * 0.05 : this.sys.game.config.height * 0.05;

                const gradient = this.createGradientBackground(col1x, rowY, gradientWidth, gradientHeight, 0xFBBC05, 0.8);


                this.page2Group.add(gradient);


                const result = selectedTable * i;
                const equation = `${selectedTable} x ${i} = ${result}`;
                if (debug) {
                    console.log('showing the times table ', equation);
                }

                const text = this.add.text(col1x, rowY, equation, {
                    fontFamily: 'gaegu',
                    fontSize: `${baseFontSize}px`,
                    color: '#f78737',
                    stroke: '#000000',
                    strokeThickness: strokeThickness,
                    align: 'left'
                }).setDepth(1000);

                this.page2Group.add(text);
            }



        }
    }
// Assuming this code is part of a Phaser 3 Scene
readyCountdown(debug, isPortrait, baseFontSize, strokeThickness, onButtons) {

    // Create a text object in the middle of the screen with matched styles
    let text = this.add.text(this.sys.game.config.width * 0.5, this.sys.game.config.height * 0.5, '', {
        fontFamily: 'gaegu',
        fontSize: `${baseFontSize*2}px`,
        color: '#f78737',
        stroke: '#000000',
        strokeThickness: strokeThickness,
        align: 'center'
    });
    text.setOrigin(0.5, 0.5); // Center the text

    // Define the sequence of texts to display
    const texts = ['Ready', 'Steady', 'Go'];
    let index = 0;

    // Reference to the scene, so 'this' can be used correctly inside the function
    const scene = this;

    // Function to update the text
    function updateText() {
        if (index < texts.length) {
            text.setText(texts[index]);
            index++;
        } else {
            text.setText(''); // Clear the text after "Go"
            // Proceed to the next page after the countdown
            scene.page3(debug, isPortrait, baseFontSize, strokeThickness, onButtons);
            return;
        }
        // Schedule the next update after 1 second
        scene.time.addEvent({
            delay: 1000,
            callback: updateText,
            callbackScope: scene // Ensure correct context for 'this'
        });
    }

    // Start the sequence
    updateText();
}






page3(debug, isPortrait, baseFontSize, strokeThickness, onButtons) {
    // Create a group to hold all elements on this page
    this.page3Group = this.add.group();

    // Add ready, steady, go!

    // start timer

    this.timerText = this.add.text(750, 10, 'Time: 0.0', {
        fontFamily: 'gaegu',
        fontSize: `${baseFontSize}px`,
    color: '#f78737',
    stroke: '#000000',
    strokeThickness: strokeThickness,
    align: 'center'
 }).setOrigin(1, 0);
    this.elapsedTime = 0;
    this.time.addEvent({
        delay: 100, // 100 milliseconds = 0.1 seconds
        callback: () => {
            this.elapsedTime += 0.1;
            this.timerText.setText('Time: ' + this.elapsedTime.toFixed(1));
        },
        callbackScope: this,
        loop: true
    });

    // display timer

    // Define layout parameters
    const width = this.sys.game.config.width;
    const height = this.sys.game.config.height;

    const headingX = width / 2;
    const headingY = height * 0.1;

    const equationsStartX = width * 0.3;
    const equationsStartY = height * 0.2;
    const equationSpacingY = height * 0.06;

    const placeholderX = width * 0.5;

    const answerAreaXMin = width * 0.6;
    const answerAreaXMax = width * 0.9;
    const answerAreaYMin = height * 0.2;
    const answerAreaYMax = height * 0.8;

    if (debug) {
        console.log('Page 3');
        console.log(onButtons);
    }

    if (onButtons.length === 1) {
        const selectedTable = parseInt(onButtons[0], 10);
        const headingText = `${selectedTable} Times Table`;

        // Create heading text
        const heading = this.add.text(headingX, headingY, headingText, {
            fontFamily: 'gaegu',
            fontSize: `${baseFontSize * 1.5}px`,
            color: '#f78737',
            stroke: '#000000',
            strokeThickness: strokeThickness,
            align: 'center'
        }).setOrigin(0.5);
        this.page3Group.add(heading);

        // Arrays to hold placeholders and answer blocks
        this.placeholders = [];
        this.answerBlocks = [];

        // Create equations and placeholders
        for (let i = 1; i <= 12; i++) {
            const result = selectedTable * i;
            const equationText = `${selectedTable} x ${i} =`;
            const posY = equationsStartY + (i - 1) * equationSpacingY;

            const gradientWidth = isPortrait ? this.sys.game.config.width * 0.1 : this.sys.game.config.width * 0.15;
            const gradientHeight = isPortrait ? this.sys.game.config.height * 0.05 : this.sys.game.config.height * 0.05;

            const gradient = this.createGradientBackground(equationsStartX, posY, gradientWidth, gradientHeight, 0xFBBC05, 0.8);


            this.page3Group.add(gradient);

            // Create equation text
            const equation = this.add.text(equationsStartX, posY, equationText, {
                fontFamily: 'gaegu',
                fontSize: `${baseFontSize}px`,
                color: '#000000',
                align: 'left'
            }).setOrigin(0, 0.5).setDepth(1000);
            this.page3Group.add(equation);

            // Create placeholder rectangle
            const placeholder = this.add.rectangle(
                placeholderX,
                posY,
                60, // Width of placeholder
                40, // Height of placeholder
                0xffffff
            ).setStrokeStyle(2, 0x000000);
            placeholder.setData('expectedResult', result);
            placeholder.setData('occupied', null); // To track occupied block
            this.page3Group.add(placeholder);
            this.placeholders.push(placeholder);
        }

        // Create shuffled array of results
        const results = Phaser.Utils.Array.Shuffle(
            Array.from({ length: 12 }, (_, i) => selectedTable * (i + 1))
        );

        // Create draggable answer blocks
        results.forEach(result => {
            const posX = Phaser.Math.Between(answerAreaXMin, answerAreaXMax);
            const posY = Phaser.Math.Between(answerAreaYMin, answerAreaYMax);

            // Create block background
            const blockBg = this.add.rectangle(0, 0, 60, 40, 0x1abc9c)
                .setStrokeStyle(2, 0x000000);

            // Create text for the block
            const blockText = this.add.text(0, 0, result, {
                fontFamily: 'gaegu',
                fontSize: `${baseFontSize}px`,
                color: '#ffffff',
                align: 'center'
            }).setOrigin(0.5);

            // Create container to hold block and text
            const blockContainer = this.add.container(posX, posY, [blockBg, blockText]);
            blockContainer.setDepth(1000);
            blockContainer.setSize(60, 40);
            blockContainer.setInteractive();
            this.input.setDraggable(blockContainer);
            blockContainer.setData('value', result);
            blockContainer.setData('originalPosition', { x: posX, y: posY });
            this.page3Group.add(blockContainer);
            this.answerBlocks.push(blockContainer);
        });

        // Drag events
        this.input.on('dragstart', (pointer, gameObject) => {
            gameObject.setDepth(1);
            // If the block was in a placeholder, clear the placeholder
            const currentPlaceholder = gameObject.getData('currentPlaceholder');
            if (currentPlaceholder) {
                currentPlaceholder.setData('occupied', null);
                gameObject.setData('currentPlaceholder', null);
            }
        });

        this.input.on('drag', (pointer, gameObject, dragX, dragY) => {
            gameObject.x = dragX;
            gameObject.y = dragY;
        });

        this.input.on('dragend', (pointer, gameObject) => {
            let placed = false;

            // Check for overlap with placeholders
            this.placeholders.forEach(placeholder => {
                if (this.checkOverlap(gameObject, placeholder) && !placeholder.getData('occupied')) {
                    // Snap block to placeholder
                    gameObject.x = placeholder.x;
                    gameObject.y = placeholder.y;
                    placeholder.setData('occupied', gameObject);
                    gameObject.setData('currentPlaceholder', placeholder);
                    placed = true;
                }
            });

            if (!placed) {
                // Return to original position if not placed on placeholder
                const { x, y } = gameObject.getData('originalPosition');
                gameObject.x = x;
                gameObject.y = y;
            }
        });

        // Optional: Add a button to check answers
        const checkButton = this.add.text(width * 0.75, height * 0.5, 'Check Answers', {
            fontFamily: 'gaegu',
            fontSize: `${baseFontSize}px`,
            color: '#ffffff',
            backgroundColor: '#27ae60',
            padding: { x: 10, y: 5 },
            align: 'center'
        }).setOrigin(0.5).setInteractive();

        checkButton.on('pointerdown', () => {
            this.checkAnswers();
        });
        this.page3Group.add(checkButton);
    }
}

// Helper function to check overlap between two game objects
checkOverlap(gameObjectA, gameObjectB) {
    const boundsA = gameObjectA.getBounds();
    const boundsB = gameObjectB.getBounds();
    return Phaser.Geom.Intersects.RectangleToRectangle(boundsA, boundsB);
}

// Function to check if all answers are correct
checkAnswers() {
    let allCorrect = true;




    this.placeholders.forEach(placeholder => {
        const occupiedBlock = placeholder.getData('occupied');
        if (occupiedBlock) {
            const value = occupiedBlock.getData('value');
            const expected = placeholder.getData('expectedResult');
            if (value === expected) {
                placeholder.setFillStyle(0x2ecc71); // Green for correct
            } else {
                placeholder.setFillStyle(0xe74c3c); // Red for incorrect
                allCorrect = false;
            }
        } else {
            placeholder.setFillStyle(0xe74c3c); // Red for missing answer
            allCorrect = false;
        }
    });
    if (allCorrect) {
        // Stop the timer
        this.time.removeAllEvents();  // This will stop all active timers
        const totalTime = parseFloat(this.elapsedTime.toFixed(1));  // Get the final elapsed time as a number

        const selectedTable = this.registry.get('selectedTable'); // Assuming this is stored earlier

        // Retrieve the best time from local storage
        const bestTimeKey = `bestTime_${selectedTable}`;
        const bestTime = parseFloat(localStorage.getItem(bestTimeKey)) || Infinity;

        if (totalTime < bestTime) {
            // Update the best time in local storage
            localStorage.setItem(bestTimeKey, totalTime);
            alert(`New record! You completed it in ${totalTime} seconds.`);
        } else {
            alert(`All answers are correct! It took you ${totalTime} seconds.`);
        }

        // Proceed to next page or reset as needed
    } else {
        alert('Some answers are incorrect. Please try again.');
    }
}






    clearTablesAnswers () {
        this.page2Group.clear(true, true);

    }

    addInputField(x, y) {
        const inputElement = this.add.dom(x, y, 'input', {
            type: 'text',
            placeholder: 'Type your answer here',
            fontSize: '24px',
            padding: '10px',
            border: '2px solid #f78737',
            backgroundColor: '#fff',
            color: '#000',
        });

        inputElement.addListener('input');
        inputElement.on('input', (event) => {
            console.log('User input:', event.target.value);
        });

        return inputElement;
    }

    update() {

    }

    updateTimer() {
        // Update the elapsed time by 0.1 seconds
        this.elapsedTime += 0.1;

        // Display the updated time in the timer text
        this.timerText.setText('Time: ' + this.elapsedTime.toFixed(1));
    }
}

export default MultiplicationScene;
