import Phaser from "phaser";
import backButton from "../assets/buttons/BackButton.png";
import fyn from '../assets/spritesheets/fynSpriteSheet.png'
class BaseScene extends Phaser.Scene {
    constructor(key) {
        super(key);
    }

    init() {
        this.registry.set('debug', false);
        const isPortrait = this.sys.game.config.width < this.sys.game.config.height;
        if (isPortrait) {
            this.registry.set('isPortrait', true);
        }
    }

    preloadCommon() {
        this.load.image('backButton', backButton);
        this.fetchUserData();
        // Define the loading text if not already defined
        if (!this.loadingText) {
            this.loadingText = this.add.text(this.sys.game.config.width / 2, this.sys.game.config.height / 2, 'Loading...', {
                fontSize: '32px',
                fill: '#f78737'
            }).setOrigin(0.5);
        }
    }

        updateLoadingText(progress) {
            if (this.loadingText) {
                this.loadingText.setText(`Loading... ${parseInt(progress * 100)}%`);
            }
        }



    shutdown() {
        window.removeEventListener('userDataFetched', handleUserDataFetched);
    }

    fetchUserData() {

        window.dispatchEvent(new CustomEvent('setupPage'));

        window.addEventListener('userDataFetched', (event) => {
            const { userData } = event.detail;
            // console.log("UserData received in Phaser:", userData);

            // Assuming 'this' is your Phaser scene and it has a 'registry'
            this.registry.set('userData', userData);

            // const checkUserData = this.registry.get('userData');
            // console.log('Current User Data:', checkUserData);
            // const checkUserPoints = this.registry.get('userData');
            // console.log('Current points:', checkUserData.points);


        });

    }

    backgroundOverlay () {
        // Create a white rectangle with initial transparency of 0 (invisible)
        const overlay = this.add.graphics();
        overlay.fillStyle(0xFFFFFF, 1); // White color, fully opaque
        overlay.fillRect(0, 0, this.sys.game.config.width, this.sys.game.config.height); // Cover the entire game area

        // Set initial alpha to 0
        overlay.alpha = 0;

        // Tween to fade in the overlay's alpha to 0.8 over 1 second (1000 ms)
        this.tweens.add({
            targets: overlay,
            alpha: 0.42,
            duration: 1000,
            ease: 'Quart.easeIn'
        });
    }


    responsiveFontSize(baseSize, scale) {
        let vmin = Math.min(this.sys.game.config.width, this.sys.game.config.height);
        let fontSize = baseSize + (vmin * scale);
        return `${fontSize}px`; // Return font size with px unit
    }

    getStrokeThickness() {
        return this.sys.game.config.width * 0.006;
    }

    getBaseFontSize(baseSize = 10, scale = 0.025) {
        return parseFloat(this.responsiveFontSize(baseSize, scale).replace('px', ''));
    }

    createAnimatedText(x, y, text, style, baseSize = null, scale = null) {
        style.fontFamily = style.fontFamily || 'gaegu';
        style.fontSize = style.fontSize || `${baseSize}px`;

        if (baseSize !== null && scale !== null) {
            let fontSize = this.responsiveFontSize(baseSize, scale);
            style.fontSize = fontSize; // Set calculated responsive font size
        }

        // Set a responsive word wrap width, e.g., 80% of the game's width
        let wrapWidth = this.sys.game.config.width * 0.8; // Adjust the percentage as needed
        style.wordWrap = { width: wrapWidth, useAdvancedWrap: true };

        // Create the text object centered at x, y with initial alpha 0
        let textBlock = this.add.text(x, y, '', style).setOrigin(0.5, 0.5).setAlpha(0);

        // Calculate the duration for each letter to appear
        const durationPerLetter = 30; // Duration per letter in milliseconds
        const totalDuration = text.length * durationPerLetter; // Total duration for all letters

        // Create a tween for the alpha effect
        this.tweens.add({
            targets: textBlock,
            alpha: { from: 0, to: 1 },
            duration: totalDuration/2, // Match the duration to the text reveal
        });

        // Reveal text letter by letter
        let currentText = '';
        for (let i = 0; i < text.length; i++) {
            this.time.delayedCall(i * durationPerLetter, () => {
                currentText += text[i]; // Add next letter
                textBlock.setText(currentText); // Update text object
            });
        }

        return textBlock; // Return the text object for further manipulation if needed
    }


    createButton(x, y, texture, hoverTexture, action, scaleMultiplier = 1, unavailable = false) {
        const button = this.add.image(x, y, texture).setInteractive();

        // Calculate the scale for visibility across different resolutions
        const baseScale = this.calculateButtonScale(button.width, button.height) * scaleMultiplier;
        button.setScale(baseScale);

        // Define hover effect values
        const hoverScaleFactor = 1.1; // Increase scale by 10% on hover
        const hoverAlpha = 1; // Reduced alpha for hover state

        if (!unavailable) {
            // Button is available, make button fully interactive
            button.on('pointerover', () => {
                if (hoverTexture) {
                    button.setTexture(hoverTexture);
                } else {
                    // Apply tween for scale and alpha adjustments
                    this.tweens.add({
                        targets: button,
                        scale: baseScale * hoverScaleFactor, // Apply hover scale
                        alpha: hoverAlpha,
                        duration: 200,
                        ease: 'Power1'
                    });
                }
            });

            button.on('pointerout', () => {
                // Check if we should revert to the original texture
                if (hoverTexture) {
                    button.setTexture(texture);
                }
                // Revert to original scale and alpha using tween
                this.tweens.add({
                    targets: button,
                    scale: baseScale, // Revert to original (base) scale
                    alpha: 1, // Full opacity
                    duration: 200,
                    ease: 'Power1'
                });
            });

            button.on('pointerdown', action);
        } else {
            // Button is unavailable, make button non-interactive and visually distinct
            button.disableInteractive();
            button.setAlpha(0.5); // Make button half transparent
            // Optionally change the texture to a greyed out version
            // button.setTexture('greyedOutTexture');
        }
        button.setDepth(1000);

        return button;
    }

    createGradientBackground(x, centerY, width, height, color, gradientStartPercent = 0.8, flipX = false) {
        const graphics = this.add.graphics();
        const halfHeight = height / 2;
        const skewAmount = halfHeight * 0.2; // Adjust the skew amount as needed

        // Flip the coordinates if flipX is true
        const startX = flipX ? x + width : x;
        const endX = flipX ? x : x + width;
        const startSkew = flipX ? -skewAmount : skewAmount;
        const gradientStartX = flipX ? startX - width * gradientStartPercent : startX + width * gradientStartPercent;
        const gradientWidth = width * (1 - gradientStartPercent);

        // Draw the solid part of the parallelogram
        graphics.fillStyle(color, 1);
        graphics.beginPath();
        graphics.moveTo(startX, centerY - halfHeight);
        graphics.lineTo(gradientStartX, centerY - halfHeight);
        graphics.lineTo(gradientStartX - startSkew, centerY + halfHeight);
        graphics.lineTo(startX - startSkew, centerY + halfHeight);
        graphics.closePath();
        graphics.fillPath();

        // Draw the gradient part of the parallelogram
        const steps = 100; // Number of gradient steps
        const alphaStep = 1 / steps;

        for (let i = 0; i < steps; i++) {
            const alpha = 1 - (i * alphaStep);
            const currentX = flipX ? gradientStartX - (i * (gradientWidth / steps)) : gradientStartX + (i * (gradientWidth / steps));
            const currentWidth = gradientWidth / steps;

            graphics.fillStyle(color, alpha);
            graphics.beginPath();
            graphics.moveTo(currentX, centerY - halfHeight);
            graphics.lineTo(currentX + (flipX ? -currentWidth : currentWidth), centerY - halfHeight);
            graphics.lineTo(currentX + (flipX ? -currentWidth : currentWidth) - startSkew, centerY + halfHeight);
            graphics.lineTo(currentX - startSkew, centerY + halfHeight);
            graphics.closePath();
            graphics.fillPath();
            graphics.setDepth(999);
        }

        return graphics;
    }







    toggleMusicAndTint() {
        const music = this.game.registry.get('backgroundMusic');
        const isMusicPlaying = this.game.registry.get('musicPlaying');

        if (isMusicPlaying) {
            // music.pause();
            music.setVolume(0);
            this.game.registry.set('musicPlaying', false);
            // Apply black and white tint to the button
            this.musicButton.setTint(0x808080); // Assuming musicButton is already defined
        } else {
            // music.resume();
            music.setVolume(0.1);
            this.game.registry.set('musicPlaying', true);
            // Clear tint from the button
            this.musicButton.clearTint();
        }
    }




    calculateButtonScale(buttonWidth, buttonHeight) {
        // Define the target size as a percentage of screen dimensions
        const targetWidthPercentage = 0.065; // Example: 10% of screen width
        const targetHeightPercentage = 0.15; // Example: 20% of screen height, adjust as needed

        // Calculate target width and height in pixels
        const targetWidth = this.sys.game.config.width * targetWidthPercentage;
        const targetHeight = this.sys.game.config.height * targetHeightPercentage;

        // Calculate scale factors for width and height
        const scaleWidth = targetWidth / buttonWidth;
        const scaleHeight = targetHeight / buttonHeight;

        // Use the smaller of the two scales to ensure the button fits well within both dimensions
        const scale = Math.max(scaleWidth, scaleHeight);

        return scale; // This scale factor can be applied to both width and height of the button
    }



    setBackground(imageKey) {
        this.background = this.add.image(0, 0, imageKey).setOrigin(0.5, 0.5);

        // Calculate and apply scale, but reduce it by a factor to create more space
        const scaleFactor = 1.3; // Increase this factor to scale down the background more
        const scaleX = (this.sys.game.config.width / this.background.width) * scaleFactor;
        const scaleY = (this.sys.game.config.height / this.background.height) * scaleFactor;
        const scale = Math.max(scaleX, scaleY);

        this.background.setScale(scale);
        this.background.setPosition(this.sys.game.config.width / 2, this.sys.game.config.height / 2);
    }



    displayQuestion(questionData, currentQuestionIndex) {
        if (this.questionText) {
            // console.log('Removing question text')
            this.questionText.destroy();
        }


        const textXPosition = this.sys.game.config.width * 0.5;
        const textYPosition = this.sys.game.config.height * 0.15;

        this.createAnimatedText(textXPosition, textYPosition, `Q${currentQuestionIndex + 1}: ${questionData.question}`, {
            fontFamily: 'gaegu',
            fontSize: `${this.getBaseFontSize()}px`,
            color: '#f78737',
            stroke: '#000000',
            strokeThickness: this.getStrokeThickness(),
            align: 'center'
        });
    }

    displayOptions(questionData, questions, currentQuestionIndex) {
        const options = questionData.buttonOptions; // Assuming this is an array of strings
        const isPortrait = this.sys.game.config.width < this.sys.game.config.height;

        const numRows = isPortrait ? options.length : (options.length > 2 ? 2 : 1); // Calculate rows based on options
        const numCols = isPortrait ? 1 : (options.length > 2 ? 2 : options.length); // Calculate cols based on options
        // Calculate button dimensions based on screen size
        const buttonWidth = this.sys.game.config.width / 8;
        const buttonHeight = buttonWidth / 2;

        // Adjust spacing based on new button dimensions (optional, could be a fixed value as well)
        const spacingX = buttonWidth * 1.75;
        const spacingY = isPortrait ? buttonHeight * 4 : buttonHeight * 1.5; // Similarly, a quarter of the button height
        const Ybuffer = isPortrait ? 0 : this.sys.game.config.height * 0.1;

        const startX = (this.sys.game.config.width - (numCols * buttonWidth + (numCols - 1) * spacingX)) / 2;
        const startY = ((this.sys.game.config.height - (numRows * buttonHeight + (numRows - 1) * spacingY)) / 2) + Ybuffer;

        for (let row = 0; row < numRows; row++) {
            for (let col = 0; col < numCols; col++) {
                const index = row * numCols + col;
                if (index < options.length) {
                    this.createOptionButton(startX, startY, row, col, buttonWidth, buttonHeight, spacingX, spacingY, options[index], questionData, questions, currentQuestionIndex);
                }
            }
        }
    }

    createSpeechBubble(x, y, width, height, quote, fontSize = 20) {
        const bubblePadding = 10;
        const arrowHeight = height / 4;

        const bubble = this.scene.add.graphics({ x: x, y: y });

        // Bubble shadow
        bubble.fillStyle(0x222222, 0.5);
        bubble.fillRoundedRect(6, 6, width, height, 16);

        // Bubble color
        bubble.fillStyle(0xffffff, 1);

        // Bubble outline line style
        bubble.lineStyle(4, 0x565656, 1);

        // Bubble shape and outline
        bubble.strokeRoundedRect(0, 0, width, height, 16);
        bubble.fillRoundedRect(0, 0, width, height, 16);

        // Calculate arrow coordinates
        const point1X = Math.floor(width / 7);
        const point1Y = height;
        const point2X = Math.floor((width / 7) * 2);
        const point2Y = height;
        const point3X = Math.floor(width / 7);
        const point3Y = Math.floor(height + arrowHeight);

        // Bubble arrow shadow
        bubble.lineStyle(4, 0x222222, 0.5);
        bubble.lineBetween(point2X - 1, point2Y + 6, point3X + 2, point3Y);

        // Bubble arrow fill
        bubble.fillTriangle(point1X, point1Y, point2X, point2Y, point3X, point3Y);
        bubble.lineStyle(2, 0x565656, 1);
        bubble.lineBetween(point2X, point2Y, point3X, point3Y);
        bubble.lineBetween(point1X, point1Y, point3X, point3Y);

        // Adding text content
        const content = this.scene.add.text(0, 0, quote, {
            fontFamily: 'Arial',
            fontSize: `${fontSize}px`,
            color: '#000000',
            align: 'center',
            wordWrap: { width: width - (bubblePadding * 2) }
        });

        // Positioning the text
        const b = content.getBounds();
        content.setPosition(x + (width / 2) - (b.width / 2), y + (height / 2) - (b.height / 2));
    }

    createOptionButton(startX, startY, row, col, buttonWidth, buttonHeight, spacingX, spacingY, text, questionData, questions, currentQuestionIndex) {
        const isPortrait = this.sys.game.config.width < this.sys.game.config.height;
        const buttonScale = isPortrait ? 1.5 : 2;

        const buttonX = startX + col * (buttonWidth + spacingX);
        const buttonY = startY + row * (buttonHeight * buttonScale + spacingY);

        let initialFontSize = isPortrait ? buttonHeight / 1.5 : buttonHeight / 3;

        // Create the button
        const button = this.createButton(buttonX, buttonY, 'logButton', null, () => {
            this.handleAnswer(text, questionData, questions, currentQuestionIndex);
        }, buttonScale);

        const leftPadding = isPortrait ? buttonWidth * 0.5: buttonWidth * 0.25;
        let wordWrapWidth = buttonWidth * 0.95;

        // Process the text to manually wrap it
        let processedText = this.customWordWrap(text, wordWrapWidth, initialFontSize, buttonWidth);

        // Add text to the button
        let textObject = this.add.text(buttonX + leftPadding, buttonY, processedText, {
            font: `${initialFontSize}px gaegu`,
            fill: '#ffffff',
            align: 'center'
        }).setOrigin(0.5, 0.5);
        textObject.setDepth(1002);

    }

    customWordWrap(text, wordWrapWidth, fontSize, maxWidth) {
        let wrappedText = '';
        let words = text.split(' ');
        let line = '';

        // Create a temporary text object to measure text width
        let testTextObject = this.add.text(0, 0, '', {
            font: `${fontSize}px gaegu`,
            fill: '#ffffff'
        });

        for (let i = 0; i < words.length; i++) {
            testTextObject.setText(line + words[i] + ' ');
            if (testTextObject.width > maxWidth && i % 3 === 0 && line.length > 0) {
                wrappedText += line.trim() + '\n'; // Add the line to the wrapped text and insert a newline
                line = words[i] + ' '; // Start a new line
            } else {
                line += words[i] + ' ';
            }
        }
        wrappedText += line.trim(); // Add the last line


        // Clean up the temporary text object
        testTextObject.destroy();

        return wrappedText;
    }



    calculateFontSize(text) {
        const baseFontSize = 30; // Base font size for button text
        const maxCharacters = 20; // Maximum characters at base font size without resizing
        let fontSize = baseFontSize;
        if (text.length > maxCharacters) {
            const scaleRatio = maxCharacters / text.length;
            fontSize = Math.max(baseFontSize * scaleRatio, 12); // Minimum font size of 12px to ensure readability
        }
        return fontSize;
    }

    createSlider(x, y, width, height, handleWidth, handleHeight, textLabel) {
        const sliderBar = this.add.image(x, y, 'sliderBar').setInteractive();
        sliderBar.displayWidth = width;
        sliderBar.displayHeight = height;

        const sliderHandle = this.add.image(x, y, 'sliderHandle').setInteractive();
        sliderHandle.displayWidth = handleWidth;
        sliderHandle.displayHeight = handleHeight;

        this.input.setDraggable(sliderHandle);

        // Padding to ensure the handle doesn't overlap the edges of the slider bar
        const padding = width * 0.1; // 10% padding on each side
        const minX = x - width / 2 + handleWidth / 2 + padding;
        const maxX = x + width / 2 - handleWidth / 2 - padding;

        sliderHandle.on('drag', (pointer, dragX, dragY) => {
            // Clamp dragX within the allowed range
            sliderHandle.x = Phaser.Math.Clamp(dragX, minX, maxX);

            // Normalize the handle's position to a value between 0 and 1, and update the level
            const normalizedValue = (sliderHandle.x - minX) / (width - 2 * padding - handleWidth);
            this.updateLevel(normalizedValue, textLabel);
        });

        return { sliderBar, sliderHandle };
    }

    setupKeyboardInputs(upCallback, downCallback) {
        this.input.keyboard.on('keydown-UP', upCallback);
        this.input.keyboard.on('keydown-DOWN', downCallback);
    }

    showCurrentButton(direction) {
        const isPortrait = this.sys.game.config.width < this.sys.game.config.height;
        const topic = this.currentTopic[this.currentTopicIndex];
        const centerX = this.sys.game.config.width / 2;
        const offScreenY = direction === 1 ? this.sys.game.config.height + 100 : -100; // Exit towards bottom if down, top if up
        const enterFromY = direction === 1 ? -100 : this.sys.game.config.height + 100; // Enter from top if down, bottom if up

        this.userData = this.registry.get('userData');
        const useLocalStorage = this.game.registry.get('localStorage');
        this.bestScoreKey = this.currentTopic[this.currentTopicIndex].key;

        if (this.userData && this.userData[this.bestScoreKey]) {
            this.bestScore = this.userData[this.bestScoreKey]?.bestScore || 0;
        } else if (useLocalStorage) {
            // Check local storage for the game data
            let gameData = JSON.parse(localStorage.getItem(this.bestScoreKey)) || {};
            this.bestScore = gameData.bestScore || 0;
        } else {
            this.bestScore = 0; // Default to 0 if neither userData nor localStorage is used
        }

        const baseFontSize = parseFloat(this.responsiveFontSize(10, 0.03).replace('px', ''));
        const strokeThickness = this.sys.game.config.width * 0.006;

        const bestScoreXPosition = isPortrait ? this.sys.game.config.width * 0.18 : this.sys.game.config.width * 0.4;
        const bestScoreYPosition = isPortrait ? this.sys.game.config.height * 0.2 : this.sys.game.config.height * 0.25;
        const textWidth = isPortrait ? this.sys.game.config.width * 0.6 : this.sys.game.config.width * 0.2;
        const textHeight = isPortrait ? this.sys.game.config.height * 0.1 : this.sys.game.config.height * 0.1;


        const attemptsYPosition = isPortrait ? this.sys.game.config.height * 0.15 : this.sys.game.config.height * 0.2;
        this.createGradientBackground(bestScoreXPosition, bestScoreYPosition, textWidth, textHeight, 0xFBBC05, 0.8);


        // Destroy the previous text objects if they exist
        if (this.pointsText) {
            this.pointsText.destroy();
        }
        if (this.attemptsText) {
            this.attemptsText.destroy();
        }
        if (this.maxPointsText) {
            this.maxPointsText.destroy();
        }
        if (this.pointsLabelText) {
            this.pointsLabelText.destroy();
        }
        if (this.graph) {
            this.graph.destroy();
        }

        // Create a new text object with the updated best score
        this.pointsText = this.add.text(bestScoreXPosition, bestScoreYPosition, `Best Score: ${this.bestScore}`, {
            fontFamily: 'gaegu',
            fontSize: `${baseFontSize}px`,
            color: '#f78737',
            stroke: '#000000',
            strokeThickness: strokeThickness,
            align: 'center'
        }).setDepth(1000);

        // Count occurrences of the current gameName
        let gameCounts = {};
        let gameAttempts = [];
        if (this.userData && this.userData.games) {
            this.userData.games.forEach(game => {
                let gameName = game.gameName;
                if (gameName === this.bestScoreKey) {
                    gameAttempts.push(game);
                }
                if (gameCounts[gameName]) {
                    gameCounts[gameName]++;
                } else {
                    gameCounts[gameName] = 1;
                }
            });
        } else if (useLocalStorage) {
                const localStorageGames = JSON.parse(localStorage.getItem('games')) || [];
                localStorageGames.forEach(game => {
                    let gameName = game.gameName;
                    if (gameName === this.bestScoreKey) {
                        gameAttempts.push(game);
                    }
                    if (gameCounts[gameName]) {
                        gameCounts[gameName]++;
                    } else {
                        gameCounts[gameName] = 1;
                    }
                });
            }

            const currentGameName = this.currentTopic[this.currentTopicIndex].key;
            const attempts = gameCounts[currentGameName] || 0;
            // console.log(`${currentGameName} attempts: ${attempts}`);



            // Create a new text object with the number of attempts
            this.attemptsText = this.add.text(bestScoreXPosition, attemptsYPosition, `Attempts: ${attempts}`, {
                fontFamily: 'gaegu',
                fontSize: `${baseFontSize}px`,
                color: '#f78737',
                stroke: '#000000',
                strokeThickness: strokeThickness,
                align: 'center'
            }).setDepth(1000);

            // Sort attempts by dateTime
            gameAttempts.sort((a, b) => new Date(a.dateTime) - new Date(b.dateTime));

            // Calculate max points for y-axis normalization
            const maxPoints = Math.max(...gameAttempts.map(game => game.points), 10);

            // Calculate x and y positions
            const graphStartX = isPortrait ? this.sys.game.config.width * 0.05 : this.sys.game.config.width * 0.1;
            const graphEndX = isPortrait ? this.sys.game.config.width * 0.4 : this.sys.game.config.width * 0.4;
            const graphStartY = isPortrait ? this.sys.game.config.height * 0.3 : this.sys.game.config.height * 0.3;
            const graphEndY = isPortrait ? this.sys.game.config.height * 0.43 : this.sys.game.config.height * 0.6;

            const xInterval = (graphEndX - graphStartX) / (gameAttempts.length - 1);
            const yScale = (graphEndY - graphStartY) / maxPoints;

            // Create the graph object
            this.graph = this.add.graphics();

            // Draw the x and y axes with responsive thickness
            const axisThickness = this.sys.game.config.width * 0.003;
            this.graph.lineStyle(axisThickness, 0x000000, 1);

            // Draw x axis
            this.graph.beginPath();
            this.graph.moveTo(graphStartX, graphEndY);
            this.graph.lineTo(graphEndX, graphEndY);
            this.graph.strokePath();

            // Draw y axis
            this.graph.beginPath();
            this.graph.moveTo(graphStartX, graphStartY);
            this.graph.lineTo(graphStartX, graphEndY);
            this.graph.strokePath();

            // Draw arrowheads on x and y axes
            const arrowSize = this.sys.game.config.width * 0.01;

            // Arrowhead for x-axis
            this.graph.beginPath();
            this.graph.moveTo(graphEndX, graphEndY);
            this.graph.lineTo(graphEndX - arrowSize, graphEndY - arrowSize / 2);
            this.graph.lineTo(graphEndX - arrowSize, graphEndY + arrowSize / 2);
            this.graph.closePath();
            this.graph.fillStyle(0x000000, 1);
            this.graph.fillPath();

            // Arrowhead for y-axis
            this.graph.beginPath();
            this.graph.moveTo(graphStartX, graphStartY);
            this.graph.lineTo(graphStartX - arrowSize / 2, graphStartY + arrowSize);
            this.graph.lineTo(graphStartX + arrowSize / 2, graphStartY + arrowSize);
            this.graph.closePath();
            this.graph.fillStyle(0x000000, 1);
            this.graph.fillPath();
            this.graph.setDepth(1003);

            // Label the maximum points on the y-axis
            this.maxPointsText = this.add.text(graphStartX - 5 * arrowSize, graphStartY, `${maxPoints}`, {
                fontFamily: 'gaegu',
                fontSize: `${baseFontSize}px`,
                color: '#000000',
                align: 'right'
            });

            // Label "Points" rotated 90 degrees counter-clockwise
            this.pointsLabelText = this.add.text(graphStartX - 5 * arrowSize, (graphStartY + graphEndY) / 2, `Points`, {
                fontFamily: 'gaegu',
                fontSize: `${baseFontSize}px`,
                color: '#000000',
                align: 'center'
            }).setOrigin(0.5).setRotation(-Math.PI / 2);

            // Draw the shadow line
            const shadowOffset = this.sys.game.config.width * 0.003;
            const lineThickness = this.sys.game.config.width * 0.006; // Double the axis thickness
            this.graph.lineStyle(lineThickness, 0x000000, 0.2); // Thinner shadow line
            this.graph.beginPath();
            gameAttempts.forEach((game, index) => {
                const x = graphStartX + index * xInterval + shadowOffset;
                const y = graphEndY - game.points * yScale + shadowOffset;
                if (index === 0) {
                    this.graph.moveTo(x, y);
                } else {
                    this.graph.lineTo(x, y);
                }
            });
            this.graph.strokePath();

            // Draw the graph line with thicker responsive thickness
            this.graph.lineStyle(lineThickness, 0xf78737, 1);
            this.graph.beginPath();
            gameAttempts.forEach((game, index) => {
                const x = graphStartX + index * xInterval;
                const y = graphEndY - game.points * yScale;
                if (index === 0) {
                    this.graph.moveTo(x, y);
                } else {
                    this.graph.lineTo(x, y);
                }
            });
            this.graph.strokePath();

        const buttonY = isPortrait ? this.sys.game.config.height * 0.55 : this.sys.game.config.height / 2 ;
        const buttonScale = isPortrait ? 2.3 : 2.7 ;


        if (this.currentButton) {
            // console.log('Level in phaser pressing button', this.level);
            this.tweens.add({
                targets: this.currentButton,
                y: direction === 1 ? this.sys.game.config.height + 100 : -100, // Exit direction based on last direction
                scale: 0.5,
                duration: 500,
                ease: 'Sine.easeIn',
                onComplete: () => {
                    this.currentButton.destroy(); // Clean up the old button
                    this.currentButton = this.createButton(centerX, enterFromY, topic.button, null, () => {
                        this.scene.start('LoadingScene', {
                            topic: this.currentTopic[this.currentTopicIndex],
                            level: this.registry.get('level')
                        });
                    }, buttonScale, !topic.available);

                    // Tween the new button into place
                    this.tweens.add({
                        targets: this.currentButton,
                        y: buttonY, // Center Y
                        duration: 500,
                        ease: 'Back.Out'
                    });
                }
            });
        } else {
            // console.log('Level in phaser pressing button', this.level);
            // First time setup, no need to tween out an old button
            this.currentButton = this.createButton(centerX, buttonY, topic.button, null, () => {
                this.scene.start('LoadingScene', {
                    topic: this.currentTopic[this.currentTopicIndex],
                    level: this.registry.get('level')
                });
            }, buttonScale);
        }
    }

    pageBar(message) {
        const baseFontSize = parseFloat(this.responsiveFontSize(10, 0.03).replace('px', ''));
        const strokeThickness = this.sys.game.config.width * 0.006;
        const isPortrait = this.sys.game.config.width < this.sys.game.config.height;
        const pageBackgroundX = this.sys.game.config.width * -0.05;
        const pageBackgroundY = isPortrait ? this.sys.game.config.height * 0.14 : this.sys.game.config.height * 0.10;
        const pageBackgroundWidth = this.sys.game.config.width * 0.3;
        const pageBackgroundHeight = this.sys.game.config.height * 0.05;
        const pageXPosition = this.sys.game.config.width * 0.15;

        // Create the UI background
        this.createGradientBackground(pageBackgroundX, pageBackgroundY, pageBackgroundWidth, pageBackgroundHeight, 0xFBBC05, 0.8);


        // // Now, add other elements to your scene, like the animated text
        this.createAnimatedText(pageXPosition, pageBackgroundY, message, {
            fontFamily: 'gaegu',
            fontSize: `${baseFontSize}px`,
            color: '#f78737',
            stroke: '#000000',
            strokeThickness: strokeThickness,
            align: 'center'
        }).setDepth(1000);
    }




    changeTopic(direction) {
        this.lastDirection = direction; // Store the direction of the change
        this.currentTopicIndex += direction;
        if (this.currentTopicIndex >= this.currentTopic.length) {
            this.currentTopicIndex = 0;
        } else if (this.currentTopicIndex < 0) {
            this.currentTopicIndex = this.currentTopic.length - 1;
        }

        this.showCurrentButton(direction);
    }

    updateLevel(normalizedValue, textLabel) {
        this.level = Math.round(normalizedValue * 29) + 1;    // Normalize to scale 0-10
        // console.log("Current Level:", this.level);
        this.registry.set('level', this.level);

        if (normalizedValue < 0.2) {
            textLabel.setText("Easy Peasy!");
        } else if (normalizedValue < 0.4) {
            textLabel.setText("Pieces of Eight");
        } else if (normalizedValue < 0.6) {
            textLabel.setText("Puzzle Pie");
        }  else if (normalizedValue < 0.8) {
            textLabel.setText("Tricky Tart");
        }  else {
            textLabel.setText("Deliciously Difficult!");
        }
    }

    clearGameData() {
        // Clearing all game-related data from the registry
        this.registry.remove('score');
        this.registry.remove('incorrectAnswers');
        this.registry.remove('currentQuestionIndex');  // Make sure to reset this as well!
    }

    createFyn(x, y) {
        const fynSprite = this.physics.add.sprite(x, y, 'fyn').setOrigin(0);
        fynSprite.setScale(0.15);
        fynSprite.body.gravity.y = 600;
        fynSprite.body.bounce.y = 0.6;
        fynSprite.setCollideWorldBounds(true);
        fynSprite.body.setDrag(400, 0);
        fynSprite.body.setMaxVelocity(400, 400);
        fynSprite.setDepth(10);
        fynSprite.preFX.setPadding(32);
        const fynGlow = fynSprite.preFX.addGlow();

        this.tweens.add({
            targets: fynGlow,
            outerStrength: 10,
            yoyo: true,
            loop: -1,
            ease: 'sine.inout'
        });

        // Add animations here as well
        this.anims.create({
            key: 'left',
            frames: this.anims.generateFrameNumbers('fyn', { start: 0, end: 7 }),
            frameRate: 20,
            repeat: -1
        });

        this.anims.create({
            key: 'right',
            frames: this.anims.generateFrameNumbers('fyn', { start: 0, end: 7 }),
            frameRate: 20,
            repeat: -1
        });

        this.anims.create({
            key: 'idle',
            frames: this.anims.generateFrameNumbers('fyn', { start: 8, end: 9 }),
            frameRate: 2
        });

        // Initialize the isJumping flag
        this.isJumping = false;

        return fynSprite;
    }

    handleFynMovement() {
        // Movement logic for Fyn
        if (this.cursors.left.isDown) {
            this.fynSprite.setVelocityX(-1400);
            this.fynSprite.anims.play('left', true);
            this.fynSprite.flipX = true;
        } else if (this.cursors.right.isDown) {
            this.fynSprite.setVelocityX(1400);
            this.fynSprite.anims.play('right', true);
            this.fynSprite.flipX = false;
        } else {
            this.fynSprite.setVelocityX(0);
            this.fynSprite.anims.play('idle', true);
        }

        // Movement logic
        if (!this.isJumping) {
            if (this.cursors.left.isDown) {
                this.fynSprite.body.setAccelerationX(-1400); // Move left
                if (!this.isJumping) this.fynSprite.anims.play('left', true);
                this.fynSprite.setFlipX(true);
            } else if (this.cursors.right.isDown) {
                this.fynSprite.body.setAccelerationX(1400); // Move right
                if (!this.isJumping) this.fynSprite.anims.play('right', true);
                this.fynSprite.setFlipX(false);
            } else {
                this.fynSprite.body.setAccelerationX(0); // Stop moving horizontally
            }
        }

        // Jump logic with isJumping check for trajectory locking
        if (this.cursors.up.isDown && !this.isJumping && (this.fynSprite.body.touching.down || this.fynSprite.body.blocked.down)) {
            this.isJumping = true; // Mark Fyn as jumping
            this.fynSprite.body.setVelocityY(-800); // Execute jump
            this.fynSprite.anims.stop(); // Stop other animations
            this.fynSprite.setTexture('fyn', 10); // Set to specific jump frame
        }
    }
    calculateMultiplier(level) {
        const maxLevel = 30;
        const minMultiplier = 1;
        const maxMultiplier = 5;
        return minMultiplier + (level - 1) * (maxMultiplier - minMultiplier) / (maxLevel - 1);
    }

    calculateAdjustedTime(level, baseTime) {
        const maxLevel = 30;
        const minTimeMultiplier = 1;
        const maxTimeMultiplier = 2;
        const timeMultiplier = minTimeMultiplier + (level - 1) * (maxTimeMultiplier - minTimeMultiplier) / (maxLevel - 1);
        return baseTime * timeMultiplier;
    }

    updatePoints(points, level) {
        const multiplier = this.calculateMultiplier(level);
        return points * multiplier;
    }


    setupPageTemplate(isPortrait, debug, pageName, backgroundImage, backScene ) {

        const Bg = this.setBackground(backgroundImage);
        this.backgroundOverlay()

        const baseFontSize = parseFloat(this.responsiveFontSize(10, 0.03).replace('px', ''));
        const smallFontSize = parseFloat(this.responsiveFontSize(10, 0.02).replace('px', ''));
        const fontSize = this.sys.game.config.width * 0.05;
        const fontSizeSmall = this.sys.game.config.width * 0.02;
        const strokeThickness = this.sys.game.config.width * 0.006;

        // Page Bar

        const pageBackgroundX = this.sys.game.config.width * -0.05;
        const pageBackgroundY = isPortrait ? this.sys.game.config.height * 0.14 : this.sys.game.config.height * 0.10;
        const pageBackgroundWidth = this.sys.game.config.width * 0.3;
        const pageBackgroundHeight = this.sys.game.config.height * 0.05;
        const pageXPosition = this.sys.game.config.width * 0.15;

        // Create the UI background
        this.createGradientBackground(pageBackgroundX, pageBackgroundY, pageBackgroundWidth, pageBackgroundHeight, 0xFBBC05, 0.8);
        // // Now, add other elements to your scene, like the animated text
        this.createAnimatedText(pageXPosition, pageBackgroundY, pageName, {
            fontFamily: 'gaegu',
            fontSize: `${baseFontSize}px`,
            color: '#f78737',
            stroke: '#000000',
            strokeThickness: strokeThickness,
            align: 'center'
        }).setDepth(1000);

        // Back Button
        const backButtonXPosition = isPortrait? this.sys.game.config.width * 0.05 : this.sys.game.config.width * 0.05;
        const backButtonYPosition = isPortrait ? this.sys.game.config.height * 0.15 : this.sys.game.config.height * 0.2;
        const backButtonScale = isPortrait ? 0.5 : 1;
        this.createButton(backButtonXPosition, backButtonYPosition, 'backButton', null, () => this.scene.start(backScene), backButtonScale);



        // Mute Button
        const musicButtonXPosition = this.sys.game.config.width * 0.95;
        const musicButtonYPosition = this.sys.game.config.height * 0.2;
        this.musicButton = this.createButton(
            musicButtonXPosition, // X position
            musicButtonYPosition, // Y position
            'musicButton', // Texture key for the button
            null, // Hover texture (null if not used)
            () => this.toggleMusicAndTint(), // Action on click
            0.5, // Scale multiplier
            false // Availability
        );

        // Star Button
        const pointsBackgroundX = isPortrait ? this.sys.game.config.width * 0.65 : this.sys.game.config.width * 0.8;
        const pointsBackgroundY = isPortrait ? this.sys.game.config.height * 0.14 : this.sys.game.config.height * 0.10;
        const pointsBackgroundWidth = this.sys.game.config.width * 0.5;
        const pointsBackgroundHeight = this.sys.game.config.height * 0.07;
        const starYPosition = isPortrait ? this.sys.game.config.height * 0.1 : this.sys.game.config.height * 0.03;
        const starXPosition = isPortrait ? this.sys.game.config.width * 0.85 : this.sys.game.config.width * 0.9;
        const starScale = isPortrait ? 0.1 : 0.2;

        this.add.image(starXPosition, starYPosition, 'pointsStar')
        .setOrigin(0, 0) // Adjust origin as needed, e.g., 0.5 for center
        .setDepth(1000)
        .setScale(starScale);
        this.createGradientBackground(pointsBackgroundX, pointsBackgroundY, pointsBackgroundWidth, pointsBackgroundHeight, 0xFBBC05, 0.8, true);







    }

    test() {
        console.log('Test function is working')
    }


}

export default BaseScene;
