import React, { useEffect, useRef } from "react";

import Phaser from "phaser";
import PreloadScene from "../scenes/PreloadScene";
import WelcomeScene from "../scenes/WelcomeScene";
import MenuScene from "../scenes/MenuScene";
import MainScene from "../scenes/MainScene";
import CreativeCarnivalScene from "../scenes/CreativeCarnivalScene";
import DictationDesertScene from "../scenes/DictationDesertScene";
import GameSummaryScene from "../scenes/GameSummaryScene";
import InterviewIslandScene from "../scenes/InterviewIslandScene";
import LoadingScene from "../scenes/LoadingScene";
import MathsMeadowScene from "../scenes/MathsMeadowScene";
import PaperPyramidScene from "../scenes/PaperPyramidScene";
import ReasoningRainforestScene from "../scenes/ReasoningRainforestScene";
import SpellingSafariScene from "../scenes/SpellingSafariScene";
import MultiplicationScene from "../scenes/MultiplicationScene";
import StoryFeedbackScene from '../scenes/StoryFeedbackScene'

import Question1Scene from "../scenes/Question1Scene";
import ExamPaper1Scene from "../scenes/ExamPaper1Scene";

function GameEngine({ isAuthenticated, userData, userSub, userRoles, startScene, navigate }) {
    const gameRef = useRef(null);

    useEffect(() => {
        const { width, height } = {
            width: window.innerWidth, // Full browser width
            height: window.innerHeight * 0.85, // 85% of the viewport height
        };

        const gameConfig = {
            type: Phaser.AUTO,
            width,
            height,
            physics: {
                default: 'arcade',
                arcade: {
                    gravity: { y: 200 },
                    debug: false
                },
            },
            scene: [
                PreloadScene,
                WelcomeScene,
                MenuScene,
                MainScene,
                CreativeCarnivalScene,
                DictationDesertScene,
                GameSummaryScene,
                InterviewIslandScene,
                LoadingScene,
                MathsMeadowScene,
                PaperPyramidScene,
                ReasoningRainforestScene,
                SpellingSafariScene,
                Question1Scene,
                ExamPaper1Scene,
                MultiplicationScene,
                StoryFeedbackScene
            ],
            dom: {
                createContainer: true
            },
        };

        // Initialize Phaser game and store reference
        gameRef.current = new Phaser.Game(gameConfig);

        // Lock the screen orientation to portrait mode
        if (screen.orientation && screen.orientation.lock) {
            screen.orientation.lock('portrait').catch(err => {
                console.error('Orientation lock failed:', err);
            });
        }


        // Set isAuthenticated and userData in Phaser's registry right after initialization
        gameRef.current.events.once('ready', () => {
            gameRef.current.registry.set('isAuthenticated', isAuthenticated);
            gameRef.current.registry.set('userData', userData);
            gameRef.current.registry.set('userSub', userSub); // Ensure userSub is also set
            gameRef.current.registry.set('userRoles', userRoles); // Ensure userSub is also set
            gameRef.current.registry.set('localStorage', false);
        });

        // Cleanup function to destroy Phaser game instance on component unmount
        return () => {
            if (gameRef.current) {
                gameRef.current.destroy(true);
                gameRef.current = null;
            }
        };
    }, []); // Removed the dependency array to ensure this runs only once
    useEffect(() => {
        if (gameRef.current && startScene) {
            gameRef.current.scene.start(startScene);
            navigate(location.pathname, { replace: true, state: {} });
        }
    }, [startScene]);
    return <div id="phaser-game" />;
}

export default GameEngine;
