export default anims => {
    anims.create({
        key: 'run',
        frames: anims.generateFrameNumbers('fyn', { start: 0, end: 7 }),
        frameRate: 20,
        repeat: -1
    });

    anims.create({
        key: 'idle',
        frames: anims.generateFrameNumbers('fyn', { start: 8, end: 9 }),
        frameRate: 2
    });

    anims.create({
        key: 'jump',
        frames: anims.generateFrameNumbers('fyn', { start: 10, end: 10 }),
        frameRate: 2
    });

}