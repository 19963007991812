import Phaser from "phaser";
import initAnimations from "./fynAnimations";

class fynSprite extends Phaser.Physics.Arcade.Sprite {
    constructor(scene, x, y) {
        super(scene, x, y, 'fyn');
        this.scene = scene;

        scene.add.existing(this);
        scene.physics.add.existing(this);

        this.init();
        this.initEvents();
    }

    init() {
        const isPortrait = this.scene.sys.game.config.width < this.scene.sys.game.config.height;
        if (isPortrait) {
            this.scene.registry.set('isPortrait', true);
        }

        const spriteScale = isPortrait ? 0.07 : 0.15;
        this.gravity = 600;
        this.playerSpeed = 400;

        this.cursors = this.scene.input.keyboard.createCursorKeys();

        this.body.setAcceleration(0, 0);
        this.setFlipX(false);

        this.setScale(spriteScale);
        this.body.gravity.y = 600;
        this.body.bounce.y = 0.2;
        this.setCollideWorldBounds(true);
         this.setDepth(1005);

        initAnimations(this.scene.anims);

    }

    initEvents() {
        this.scene.events.on(Phaser.Scenes.Events.UPDATE, this.update, this);
    }

    update() {
        const { left, right, space } = this.cursors;
        const onFloor = this.body.onFloor();

        if (left.isDown) {
            this.setVelocityX(-this.playerSpeed);
            this.setFlipX(true);
        } else if (right.isDown) {
            this.setVelocityX(this.playerSpeed);
            this.setFlipX(false);
        } else {
            this.setVelocityX(0);
        }

        if (space.isDown && onFloor) {
            this.setVelocityY(-this.playerSpeed);
        }

        onFloor ?
            this.body.velocity.x !== 0 ?
                this.play('run', true) : this.play('idle', true) :
                this.play('jump', true);


    }

}

export default fynSprite;