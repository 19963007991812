// useUserData.js

import { useEffect, useState } from 'react';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:5001';

const useUserData = () => {
  const [isStudent, setIsStudent] = useState(false);
  const [isParent, setIsParent] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [points, setPoints] = useState(0);
  const [userData, setUserData] = useState(null);
  const [studentName, setStudentName] = useState('');

  const fetchUserData = async (userSub, nickname, email) => {
    try {
      const response = await fetch(`${API_BASE_URL}/users/${userSub}`, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        const userData = await response.json();
        setUserData(userData);
        setIsStudent(userData?.rights?.canAccessAsStudent || false);
        setIsParent(userData?.rights?.canAccessAsParent || false);
        setIsAdmin(userData?.rights?.canAccessAsAdmin || false);
        setPoints(userData?.points || 0);
        setStudentName(userData?.studentName || '');
      } else if (response.status === 404) {
        // User not found, create a new user
        await createUser(userSub, nickname, email);
      } else {
        console.error('Failed to fetch user data');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const createUser = async (userSub, nickname, email) => {
    try {
      const defaultRights = {
        canAccessAsStudent: false,
        canAccessAsParent: false,
        canAccessAsAdmin: false,
      };

      const response = await fetch(`${API_BASE_URL}/users`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: userSub,
          rights: defaultRights,
          points: 0,
          maths_last_date: '01/07/23',
          studentName: '', // Add the "maths_last_date" field here
          nickname: nickname, // Add nickname
          email: email, // Add email
        }),
      });

      if (response.ok) {
        const newUser = await response.json();
        // console.log('User created:', newUser);
        setPoints(newUser.points); // Update the points in your React state
        setUserData(newUser);
        setIsStudent(newUser?.rights?.canAccessAsStudent || false);
        setIsParent(newUser?.rights?.canAccessAsParent || false);
        setIsAdmin(newUser?.rights?.canAccessAsAdmin || false);
      } else {
        console.error('Failed to create user');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const fetchGameData = async (userSub, dbName) => {
    try {
      const response = await fetch(`${API_BASE_URL}/users/${userSub}/${dbName}`, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        const data = await response.json();
        return data[dbName];  // Return the fetched data
      } else {
        console.error(`Failed to fetch ${dbName} data`);
        return null;
      }
    } catch (error) {
      console.error('Error:', error);
      return null;
    }
  };


  const updatePoints = async (userSub, correctAnswers, gameName, dbName, totalScore = 0) => {
    try {
      const dateTime = new Date().toISOString();  // Get the current date/time
      const response = await fetch(`${API_BASE_URL}/users/update_points`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name: userSub, correctAnswers, gameName, dateTime, dbName, totalScore }),
      });

      if (response.ok) {
        const result = await response.json();
        // Handle success
        setPoints(points + correctAnswers); // Update points
      } else {
        console.error('Failed to update points');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const updatePhaserPoints = async (userSub, correctAnswers, gameName, level, totalScore, incorrectAnswers = [], subject) => {
    try {
      // console.log('updating phaser points')
      const dateTime = new Date().toISOString();  // Get the current date/time
      const response = await fetch(`${API_BASE_URL}/users/update_phaser_points`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: userSub,
          correctAnswers: correctAnswers,
          gameName: gameName,
          dateTime: dateTime,
          totalScore: totalScore,
          level: 1,
          incorrectAnswers: incorrectAnswers,
          subject: subject
        }),
      });

      if (response.ok) {
        const result = await response.json();
        // Handle success
        // console.log('Update successful:', result);
        setPoints(points + correctAnswers); // Assuming 'setPoints' updates state correctly
      } else {
        console.error('Failed to update points:', await response.text());
      }
    } catch (error) {
      console.error('Error while updating points:', error);
    }
  };

  const reduceIncorrectCounters = async (userSub, correctAnswers, reduceAnswers = []) => {
    try {
      // console.log('updating phaser points')
      const dateTime = new Date().toISOString();  // Get the current date/time
      const response = await fetch(`${API_BASE_URL}/users/reduce_incorrect_counter`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: userSub,
          correctAnswers: correctAnswers,
          reduceAnswers: reduceAnswers
        }),
      });

      if (response.ok) {
        const result = await response.json();
        // Handle success
        // console.log('Update successful:', result);
        setPoints(points + correctAnswers); // Assuming 'setPoints' updates state correctly
      } else {
        console.error('Failed to update points:', await response.text());
      }
    } catch (error) {
      console.error('Error while updating points:', error);
    }
  };


  const updateStudentName = async ({ userSub, studentName }) => {
    try {
      const response = await fetch(`${API_BASE_URL}/users/update_student_name`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ userSub, studentName }),
      });

      // rest of the code...
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const updateStudentNameInUserData = (newStudentName) => {
    setUserData((prevUserData) => ({
      ...prevUserData,
      studentName: newStudentName,
    }));
  };

  const levelUp = async (userSub, dbName) => {
    try {
      const response = await fetch(`${API_BASE_URL}/users/level_up`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name: userSub, dbName }),
      });

      if (response.ok) {
        const result = await response.json();
        // Update your local state here to reflect the new level.
        // For example: setLevel(result.newLevel);
      } else {
        console.error('Failed to level up');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const fetchWeeksGames = async (userId) => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/get-games?user_id=${userId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          // Add any required headers here, like authorization tokens
        },
      });

      if (response.ok) {
        const games = await response.json();
        return games;
      } else {
        console.error('Failed to fetch games');
        return [];
      }
    } catch (error) {
      console.error('Error:', error);
      return [];
    }
  };


  return { isStudent, isParent, isAdmin, points, userData, fetchUserData,fetchGameData, updatePoints, updatePhaserPoints, reduceIncorrectCounters, updateStudentName, studentName, setStudentName, updateStudentNameInUserData, levelUp, fetchWeeksGames };

};

export default useUserData;
