// sharedAssets.js
import background from "../assets/backgrounds/IntroPage.jpg";
import fyn from '../assets/spritesheets/fynSpriteSheet.png';

import pointsStar from '../assets/PointsStar.png';

import storyBackground from "../assets/backgrounds/StoryWriter.jpg";

import loginButton from "../assets/buttons/LoginButton.png";
import nextButton from "../assets/buttons/NavButton.png";
import musicButton from "../assets/buttons/MusicButton.png";
import backButton from "../assets/buttons/BackButton.png";
import navButton from "../assets/buttons/NavButton.png";
import onRadioButton from "../assets/buttons/OnRadioButton.png";
import offRadioButton from "../assets/buttons/OffRadioButton.png";
import sevenPlusButton from "../assets/buttons/7PlusButton.png";
import eightPlusButton from "../assets/buttons/8PlusButton.png";
import elevenPlusButton from "../assets/buttons/11PlusButton.png";
import reportCardButton from "../assets/buttons/ReportCardButton.png";
import cursorButton from "../assets/buttons/CursorButton.png";
import jumpButton from "../assets/buttons/JumpButton.png";
import palindromeButton from "../assets/buttons/PalindromeButton.png";
import trueOrNotButton from "../assets/buttons/TrueOrNotButton.png";
import codewordButton from "../assets/buttons/CodewordButton.png";
import storyWriterButton from  "../assets/buttons/StoryWriterButton.png";

import pixelBackground from  "../assets/backgrounds/MainPixel.jpg";

import menuBackground from "../assets/backgrounds/MenuPage.jpg";
import ccButton from "../assets/buttons/CreativeCarnivalButton.png";
import ddButton from "../assets/buttons/DictationDesertButton.png";
import iiButton from "../assets/buttons/InterviewIslandButton.png";
import mmButton from "../assets/buttons/MathsMeadowButton.png";
import ppButton from "../assets/buttons/PaperPyramidButton.png";
import rrButton from "../assets/buttons/ReasoningRainforestButton.png";
import ssButton from "../assets/buttons/SpellingSafariButton.png";

import welcomeGround from "../assets/scenery/welcomeGround.png";

export function loadAllAssets(scene) {
    scene.load.image('backButton', backButton);
    scene.load.image('navButton', navButton);
    scene.load.image('onRadioButton', onRadioButton);
    scene.load.image('offRadioButton', offRadioButton);
    scene.load.image('backgroundImage', background);
    scene.load.image('storyBackground', storyBackground);

    scene.load.image('loginButton', loginButton);
    scene.load.image('nextButton', nextButton);
    scene.load.image('musicButton', musicButton);
    scene.load.image('sevenPlusButton', sevenPlusButton);
    scene.load.image('eightPlusButton', eightPlusButton);
    scene.load.image('elevenPlusButton', elevenPlusButton);
    scene.load.image('reportCardButton', reportCardButton);
    scene.load.image('jumpButton', jumpButton);
    scene.load.image('palindromeButton', palindromeButton);
    scene.load.image('codewordButton', codewordButton);
    scene.load.image('trueOrNotButton', trueOrNotButton);
    scene.load.image('cursorButton', cursorButton);
    scene.load.image('storyWriterButton', storyWriterButton);

    scene.load.image('pointsStar', pointsStar);

    scene.load.image('mmButton', mmButton);
    scene.load.image('ccButton', ccButton);
    scene.load.image('ddButton', ddButton);
    scene.load.image('iiButton', iiButton);
    scene.load.image('ppButton', ppButton);
    scene.load.image('rrButton', rrButton);
    scene.load.image('ssButton', ssButton);



    scene.load.image('welcomeGround', welcomeGround);
    scene.load.audio('islandFlex', '/IslandFlex.mp3');
    scene.load.audio('cuba', '/cuba.mp3');
    scene.load.audio('beforeStacy', '/beforeStacy.mp3');
    scene.load.audio('hornting', '/hornting.mp3');
    scene.load.audio('Lovestealing', '/Lovestealing.mp3');
    scene.load.audio('royalFlush', '/royalFlush.mp3');
    scene.load.spritesheet('fyn', fyn, { frameWidth: 801, frameHeight: 2002 });

    // Update the display as assets load
    scene.load.on('progress', (value) => {
        scene.loadingText.setText(`Loading... ${parseInt(value * 100)}%`);
    });
}
