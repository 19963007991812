import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import GameEngine from '../gameEngine/GameEngine';
import { useAuth0 } from '@auth0/auth0-react';
import useUserData from '../hooks/useUserData';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import generateSpeech from '../components/generateSpeech';

// Maths Questions

import { generateOddOrEvenQuestion } from '../mathsQuestions/oddOrEvenQuestion';
import { generateSimpleQuestion } from '../mathsQuestions/simpleQuestion';
import { generateSymbolQuestion } from '../mathsQuestions/symbol';
import { generateTimeQuestion } from '../mathsQuestions/time';
import { generateTimesTableQuestion } from '../mathsQuestions/timestables';
import { generateDivisionQuestion } from '../mathsQuestions/division';
import { generateFractionMathQuestion } from '../mathsQuestions/fractions';
import { generateSequenceQuestion } from '../mathsQuestions/sequence';
import { generateDayInMonthQuestion } from '../mathsQuestions/months';
import { generateMinMaxNumberQuestion } from '../mathsQuestions/comparing';
import { generateMoneyQuestion } from '../mathsQuestions/coins';
import { generateAlgebraQuestion } from '../mathsQuestions/algebra';
import { generateRoundingQuestion } from '../mathsQuestions/rounding';
import { generateConversionQuestion } from '../mathsQuestions/unitconversion';

//Creative Questions

import { generateAdjectiveQuestion } from '../creativeQuestions/adjective';
import { generateWordMeaningQuestion } from '../creativeQuestions/wordBank';
import { generateMetaphorQuestion } from '../creativeQuestions/metaphor';
import { generatePunctuationQuestion } from '../creativeQuestions/punctuation';
import { generateSpeechWordsQuestion } from '../creativeQuestions/speechAlternatives';
import { generateSimileQuestion } from '../creativeQuestions/similie';
import { generateIdiomQuestion } from '../creativeQuestions/idiom';
import { generateProverbQuestion } from '../creativeQuestions/proverb';
import { generateCollectiveQuestion } from '../creativeQuestions/collections';

//Reasoning Questions
import { generatePalindromeQuestion } from '../reasoningQuestions/palindrone';
import { generateTrueFalseQuestion } from '../reasoningQuestions/trueFalse';
import { generateCodeWordQuestion } from '../reasoningQuestions/codeword';

function Game() {
    const { isAuthenticated, user, loginWithRedirect } = useAuth0();
    const { userData, fetchUserData, updatePhaserPoints, reduceIncorrectCounters } = useUserData();
    const navigate = useNavigate();
    const [isUserDataReady, setIsUserDataReady] = useState(false);
    const [points, setPoints] = useState(0);
    const [newUserData, setNewUserData] = useState(null);
    const [studentName, setStudentName] = useState('');
    const [userRoles, setUserRoles] = useState([]);
    const location = useLocation();
    const startScene = location.state?.startScene;

    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:5001';

    useEffect(() => {
        // console.log('UserData:', userData);
        // console.log('UserSub in react:', user?.sub);

    }, [userData]);

    //this is an empty one as an example
    // useEffect(() => {
    //     // Define the function that will be called when the event is received
    //     const handleSetupPage = () => {
    //         console.log('setupPage event received!');
    //     };

    //     // Add event listener for the 'setupPage' event
    //     window.addEventListener('setupPage', handleSetupPage);

    //     // Clean up the event listener when the component unmounts
    //     return () => {
    //         window.removeEventListener('setupPage', handleSetupPage);
    //     };
    // }, []);

    // Setting up the scene

    useEffect(() => {
        if (isAuthenticated && user) {
          const roles = user['https://fynschool.com/roles'];
        //   const message = roles ? "Welcome, ${studentName}!" : "Welcome, guest!";
        //   const randomMessage =
        //     roles ? customMessages[Math.floor(Math.random() * customMessages.length)] : "Welcome, guest!";
        //   setCustomMessage(randomMessage);
          setUserRoles(roles);
        //   console.log('Roles are:', userRoles)
        } else {
            setUserRoles([]);
            // console.log('Roles are:', userRoles)
        }
      }, [isAuthenticated, user]);


    useEffect(() => {
        // Define the function that will be called when the event is received
        const handleSetupPage = async () => {
            // console.log('setupPage event received!');
            if (!user.sub) {
              console.error("user.sub is undefined or invalid");
              return;
            }

            try {
                const response = await fetch(`${API_BASE_URL}/users/${user.sub}`, {
                    headers: {
                      'Content-Type': 'application/json',
                    },
                  });

                  if (response.ok) {
                    const NuserData = await response.json();
                    // console.log('New fetch is..', NuserData)

                    window.dispatchEvent(new CustomEvent('userDataFetched', { detail: { userData: NuserData } }));


                  } else if (response.status === 404) {
                    // User not found, create a new user
                    await createUser(userSub, nickname, email);
                  } else {
                    console.error('Failed to fetch user data');
                  }
            } catch (error) {
              console.error('Fetching user data failed:', error);
            }
          };

          window.addEventListener('setupPage', handleSetupPage);

          return () => {
            window.removeEventListener('setupPage', handleSetupPage);
          };
    }, []);



    useEffect(() => {
        if (isAuthenticated && user?.sub) {
            // console.log('fetching user data');
            fetchUserData(user.sub)
                .then(() => {
                    setIsUserDataReady(true); // Indicate user data is ready
                })
                .catch((error) => console.error('Fetching user data failed:', error));
        } else {
            setIsUserDataReady(true); // User is not authenticated, proceed as guest
        }
    }, [isAuthenticated, user?.sub]);

    useEffect(() => {
        const handleUpdatePhaserPoints = async (event) => {
            // console.log('Dispatching phaser point event')
            const { userSub, correctAnswers, gameName, level, totalScore, incorrectAnswers, subject } = event.detail;
            if (isAuthenticated && user?.sub) {
                try {
                    await updatePhaserPoints(userSub, correctAnswers, gameName, level, totalScore, incorrectAnswers, subject);
                    setPoints(prevPoints => prevPoints + correctAnswers);
                    // console.log('Updated')
                } catch (error) {
                    console.error("Failed to update Phaser points:", error);
                }
            }
        };

        window.addEventListener('updatePhaserPoints', handleUpdatePhaserPoints);
        return () => window.removeEventListener('updatePhaserPoints', handleUpdatePhaserPoints);
    }, []);

    useEffect(() => {
        const handleReduceIncorrectCounters = async (event) => {
            const { userSub, correctAnswers, reduceAnswers } = event.detail;
            // console.log('Handling reduceIncorrectCounters event', {
            //     userSub,
            //     correctAnswers,
            //     reduceAnswers
            // });

            if (isAuthenticated && user?.sub) {
                try {
                    await reduceIncorrectCounters(userSub, correctAnswers, reduceAnswers);
                    setPoints(prevPoints => prevPoints + correctAnswers);
                } catch (error) {
                    console.error("Failed to update Phaser points:", error);
                }
            }
        };

        window.addEventListener('reduceIncorrectCounters', handleReduceIncorrectCounters);
        return () => window.removeEventListener('reduceIncorrectCounters', handleReduceIncorrectCounters);
    }, []);

    // useEffect(() => {
    //     console.log("Loaded scene:", startScene);  // Check the console to see if the scene logs correctly
    // }, [startScene]);

    useEffect(() => {
        const handleStorySubmitted = async (event) => {
          const { story } = event.detail;
        //   console.log('Story received from Phaser:', story);

          try {
            // Send the story for feedback analysis
            const response = await axios.post(`${API_BASE_URL}/analyse_story`, { story });
            const feedbackText = response.data.feedback;

            // Play the feedback audio once the text is ready
            const audioUrl = await generateSpeech(feedbackText);
            const audio = new Audio(audioUrl);
            audio.play();

            // Dispatch an event with feedback back to Phaser
            window.dispatchEvent(new CustomEvent('feedbackReturned', {
                detail: { feedbackText }
            }));

          } catch (error) {
            console.error('Error processing story:', error);
          }
        };

        window.addEventListener('storySubmitted', handleStorySubmitted);

        return () => {
          window.removeEventListener('storySubmitted', handleStorySubmitted);
        };
    }, []);




    useEffect(() => {
        // Other useEffect logic remains the same
        const handleLoginTrigger = () => loginWithRedirect();
        window.addEventListener('triggerLogin', handleLoginTrigger);

        // Adjust for navigate
        const handleNavigateTo = (event) => {
            const { path } = event.detail;
            navigate(path); // Use navigate for React Router v6
        };
        window.addEventListener('navigateTo', handleNavigateTo);

        return () => {
            window.removeEventListener('triggerLogin', handleLoginTrigger);
            window.removeEventListener('navigateTo', handleNavigateTo);
        };
    }, [loginWithRedirect, navigate]); // or [loginWithRedirect, navigate] for v6

    useEffect(() => {
        const generateQuestions = (event) => {
            const { reactComponent, level } = event.detail;
            // console.log('Level:', level)
            let questionGenerator;
            let mixedQuestions = false;

            // Map `reactComponent` to your question generation functions
            switch(reactComponent) {
                case 'generateOddOrEvenQuestion':
                    questionGenerator = generateOddOrEvenQuestion;
                    break;
                case 'generateSimpleQuestion':
                    questionGenerator = generateSimpleQuestion;
                    break;
                case 'generateSymbolQuestion':
                    questionGenerator = generateSymbolQuestion;
                    break;
                case 'generateTimeQuestion':
                    questionGenerator = generateTimeQuestion;
                    break;
                case 'generateTimesTableQuestion':
                    questionGenerator = generateTimesTableQuestion;
                    break;
                case 'generateDivisionQuestion':
                    questionGenerator = generateDivisionQuestion;
                    break;
                case 'generateFractionMathQuestion':
                    questionGenerator = generateFractionMathQuestion;
                    break;
                case 'generateSequenceQuestion':
                    questionGenerator = generateSequenceQuestion;
                    break;
                case 'generateDayInMonthQuestion':
                    questionGenerator = generateDayInMonthQuestion;
                    break;
                case 'generateMinMaxNumberQuestion':
                    questionGenerator = generateMinMaxNumberQuestion;
                    break;
                case 'generateMoneyQuestion':
                    questionGenerator = generateMoneyQuestion;
                    break;
                case 'generateAlgebraQuestion':
                    questionGenerator = generateAlgebraQuestion;
                    break;
                case 'generateRoundingQuestion':
                    questionGenerator = generateRoundingQuestion;
                    break;
                case 'generateConversionQuestion':
                    questionGenerator = generateConversionQuestion;
                    break;
                case 'generateWordMeaningQuestion':
                    questionGenerator = generateWordMeaningQuestion;
                    break;
                case 'generateAdjectiveQuestion':
                    questionGenerator = generateAdjectiveQuestion;
                    break;
                case 'generateMetaphorQuestion':
                    questionGenerator = generateMetaphorQuestion;
                    break;
                case 'generatePunctuationQuestion':
                    questionGenerator = generatePunctuationQuestion;
                    break;
                case 'generateSpeechWordsQuestion':
                    questionGenerator = generateSpeechWordsQuestion;
                    break;
                case 'generateSimileQuestion':
                    questionGenerator = generateSimileQuestion;
                    break;
                case 'generateIdiomQuestion':
                    questionGenerator = generateIdiomQuestion;
                    break;
                case 'generateProverbQuestion':
                    questionGenerator = generateProverbQuestion;
                    break;
                case 'generateCollectiveQuestion':
                    questionGenerator = generateCollectiveQuestion;
                    break;
                case 'generatePalindromeQuestion':
                    questionGenerator = generatePalindromeQuestion;
                    break;
                case 'generateCodeWordQuestion':
                    questionGenerator = generateCodeWordQuestion;
                    break;
                case 'generateTrueFalseQuestion':
                    questionGenerator = generateTrueFalseQuestion;
                    break;
                case 'generate7plusMaths':
                    questionGenerator = generateSimpleQuestion;
                    mixedQuestions = true
                    break;



                default:
                    console.error("Unknown component name:", reactComponent);
                    return;
            }

            const questions = [];
            if (mixedQuestions) {
                console.log('Mixed question mode i.e. 7+')
                for (let i = 0; i < 5; i++) {
                    // Assuming level or other needed parameters are handled inside
                    questions.push(questionGenerator(level));
                }

            } else {
                for (let i = 0; i < 100; i++) {
                    // Assuming level or other needed parameters are handled inside
                    questions.push(questionGenerator(level));
                }
            }

            // Dispatch an event with the generated questions back to Phaser
            window.dispatchEvent(new CustomEvent('questionsGenerated', {
                detail: {
                    questions: questions
                }
            }));
        };

        window.addEventListener('generateQuestions', generateQuestions);

        return () => {
            window.removeEventListener('generateQuestions', generateQuestions);
        };
    }, []);



    return (
        <div className='Game'>
            {isUserDataReady && (
                <GameEngine
                    startScene={startScene}
                    navigate={navigate}
                    isAuthenticated={isAuthenticated}
                    userData={userData}
                    userSub={user?.sub}
                    userRoles={userRoles}
                />
            )}
        </div>
    );
}

export default Game;
