import BaseScene from "./BaseScene";
import fynSprite from "../sprites/fynSprite";

class StoryFeedbackScene extends BaseScene {
    constructor() {
        super('StoryFeedbackScene');
        this.cursorPosition = 0;
    }

    init() {
        // Now you have access to `data`
        // this.registry.set('debug', true);
        this.registry.set('debug', false);
        const isPortrait = this.sys.game.config.width < this.sys.game.config.height;
        if (isPortrait) {
            this.registry.set('isPortrait', true);
        }



            // Initialize the questions list here
    this.storyQuestions = [
        { key_id: 1, question: "Type or Paste in your story", answer: "" },
        // { key_id: 1, question: "Who is the main character?", answer: "" },
        // { key_id: 2, question: "Where are they?", answer: "" },
        // { key_id: 3, question: "What does the scenery look like?", answer: "" },
        // Add more questions as needed
    ];
    this.currentQuestionIndex = 0;

    }

    create() {
        const debug = this.registry.get('debug');
        const isPortrait = this.registry.get('isPortrait');

        const screenWidth = this.sys.game.config.width;
        const screenHeight = this.sys.game.config.height;

        if (debug) {
            console.log('StoryFeedbackScene is active.');
            console.log(this.storyQuestions);
        }
        this.setupPageTemplate(isPortrait, debug, 'Story Writer', 'storyBackground', 'CreativeCarnivalScene' );
        const fyn = new fynSprite(this, screenWidth * 0.2, 250);


        // Set background dimensions to 90% of screen width
        const backgroundWidth = screenWidth * 0.9;
        const backgroundHeight = screenHeight * 0.8;

    // Set up background with 50% alpha
    const sceneBackground = this.add.rectangle(
        screenWidth / 2, screenHeight / 2,
        backgroundWidth, backgroundHeight,
        0xf0f0f0
    ).setAlpha(0.5); // 50% alpha for the scene background
    sceneBackground.setOrigin(0.5, 0.5);

    // Set text area dimensions to 80% of screen width
    const textBoxWidth = screenWidth * 0.8;
    const textBoxHeight = screenHeight * 0.3;

    // Create a rectangle for the text area with 80% alpha
    const textAreaBackground = this.add.rectangle(
        screenWidth / 2, screenHeight / 2,
        textBoxWidth, textBoxHeight,
        0xd3d3d3
    ).setAlpha(0.8); // 80% alpha for the text area
    textAreaBackground.setOrigin(0.5, 0.5);



        // Center the question text
        this.questionText = this.add.text(screenWidth / 2, screenHeight * 0.3, this.storyQuestions[this.currentQuestionIndex].question, {
            fontFamily: 'Arial',
            fontSize: '16px',
            color: '#000000',
            wordWrap: { width: textBoxWidth, useAdvancedWrap: true },
            maxLines: 1
        }).setOrigin(0.5); // Center the question text

        // Create a Phaser text object to act as the textarea
        this.textArea = this.add.text(screenWidth / 2, screenHeight / 2, '', {
            fontFamily: 'Arial',
            fontSize: '16px',
            color: '#000000',
            wordWrap: { width: textBoxWidth - 20, useAdvancedWrap: true }, // Text box width
            maxLines: 8
        }).setOrigin(0.5); // Center the text area

        // Create a cursor, centered with the text area
        this.cursor = this.add.text(screenWidth / 2, screenHeight / 2, '|', {
            fontFamily: 'Arial',
            fontSize: '16px',
            color: '#000000'
        }).setOrigin(0.5);

        // Cursor blinking animation
        this.time.addEvent({
            delay: 500,
            callback: () => {
                this.cursor.setVisible(!this.cursor.visible);
            },
            loop: true
        });

        // Capture keyboard input
        this.input.keyboard.on('keydown', (event) => {
            if (event.key === 'Enter') {
                this.processText(this.textArea.text);
                return;
            }

            if (event.key === 'Backspace') {
                if (this.cursorPosition > 0) {
                    this.textArea.text = this.textArea.text.slice(0, this.cursorPosition - 1) + this.textArea.text.slice(this.cursorPosition);
                    this.cursorPosition--;
                }
                this.updateCursor();
                return;
            }

            if (event.key.length === 1) {
                this.textArea.text = this.textArea.text.slice(0, this.cursorPosition) + event.key + this.textArea.text.slice(this.cursorPosition);
                this.cursorPosition++;
                this.updateCursor();
            }
        });

        // Handle clipboard paste (Ctrl+V or Command+V)
        this.input.keyboard.on('keydown', (event) => {
            if ((event.ctrlKey || event.metaKey) && event.key === 'v') {
                navigator.clipboard.readText().then((clipText) => {
                    this.textArea.text = this.textArea.text.slice(0, this.cursorPosition) + clipText + this.textArea.text.slice(this.cursorPosition);
                    this.cursorPosition += clipText.length;
                    this.updateCursor();
                });
            }
        });

        // Create a process button
        const processButton = this.add.rectangle(screenWidth * 0.5, screenHeight * 0.8, 150, 40, 0x007bff).setInteractive();
        const buttonText = this.add.text(0, 0, 'Process Text', {
            fontFamily: 'Arial',
            fontSize: '18px',
            color: '#ffffff',
        }).setOrigin(0.5);
        Phaser.Display.Align.In.Center(buttonText, processButton);

        // Handle button click
        processButton.on('pointerdown', () => {
            this.processText(this.textArea.text);
        });

        // Create a paste button
        const pasteButton = this.add.rectangle(screenWidth * 0.5, screenHeight * 0.85, 150, 40, 0x007bff).setInteractive();
        const pasteButtonText = this.add.text(0, 0, 'Paste', {
            fontFamily: 'Arial',
            fontSize: '18px',
            color: '#ffffff',
        }).setOrigin(0.5);
        Phaser.Display.Align.In.Center(pasteButtonText, pasteButton);

        // Handle paste button click
        pasteButton.on('pointerdown', () => {
            navigator.clipboard.readText().then((clipText) => {
                this.textArea.text = this.textArea.text.slice(0, this.cursorPosition) + clipText + this.textArea.text.slice(this.cursorPosition);
                this.cursorPosition += clipText.length;
                this.updateCursor();
            });
        });

        // Create a clear text button (Red color)
        const clearButton = this.add.rectangle(screenWidth * 0.5, screenHeight * 0.9, 150, 40, 0xff0000).setInteractive();
        const clearButtonText = this.add.text(0, 0, 'Clear Text', {
            fontFamily: 'Arial',
            fontSize: '18px',
            color: '#ffffff',
        }).setOrigin(0.5);
        Phaser.Display.Align.In.Center(clearButtonText, clearButton);

        // Handle clear text button click
        clearButton.on('pointerdown', () => {
            this.textArea.text = ''; // Clear the text area
            this.cursorPosition = 0; // Reset the cursor position
            this.updateCursor();     // Update the cursor
        });
        if (debug) {
            console.log('Custom Phaser text area created.');
        }


            // Listen for the feedbackReturned event and display the feedback
        window.addEventListener('feedbackReturned', (event) => {
            const { feedbackText } = event.detail;
            this.displayFeedbackOverlay(feedbackText);
        });
    }

    updateCursor() {
        const lines = this.textArea.getWrappedText(this.textArea.text);
        let charCount = 0;
        let lineIndex = 0;

        // Determine which line the cursor is on
        for (let i = 0; i < lines.length; i++) {
            if (this.cursorPosition <= charCount + lines[i].length) {
                lineIndex = i;
                break;
            }
            charCount += lines[i].length;
        }

        // Calculate the offset in the line
        const lineOffset = this.cursorPosition - charCount;

        // Update the cursor position (centered relative to the text area)
        const textBoxWidth = this.sys.game.config.width * 0.8;
        const textAreaX = this.sys.game.config.width / 2 - textBoxWidth / 2; // Starting X position of text area
        this.cursor.setX(textAreaX + lineOffset * this.textArea.style.fontSize * 0.6); // Adjust x position based on character width
        this.cursor.setY(this.textArea.y + lineIndex * this.textArea.style.fontSize * 1.2); // Adjust y position based on line height
    }


    processText(text) {
        const debug = this.registry.get('debug');
        // Ensure we're within bounds of the storyQuestions array

        if (this.currentQuestionIndex < this.storyQuestions.length) {
            const currentQuestion = this.storyQuestions[this.currentQuestionIndex];
            currentQuestion.answer = text;

            // Move to the next question
            this.currentQuestionIndex++;

            if (this.currentQuestionIndex < this.storyQuestions.length) {
                // Update the displayed question without clearing the text
                this.questionText.setText(this.storyQuestions[this.currentQuestionIndex].question);
            } else {
                // No more questions, log the answers or handle the end of questionnaire
                if (debug) {

                    console.log('All questions answered:', this.storyQuestions);
                }


                // Trigger an event to send the story to React once all questions are completed
                const storyEvent = new CustomEvent('storySubmitted', {
                    detail: { story: this.compileStory() } // Send the compiled story
                });
                window.dispatchEvent(storyEvent);

                // Enable resubmission by allowing the user to resubmit the current text
                this.currentQuestionIndex = 0; // Reset index for potential resubmission
            }
        } else {
            // Allow resubmission without clearing the text
            if (debug) {

                console.log('Resubmitting story.');
            }
            const resubmitEvent = new CustomEvent('storySubmitted', {
                detail: { story: this.compileStory() }
            });
            window.dispatchEvent(resubmitEvent);
        }
    }


    compileStory() {
        // Combine answers from all questions into a single string
        return this.storyQuestions.map(q => q.answer).join(' ');
    }

    displayFeedbackOverlay(feedbackText) {
        // Debugging log to see if 'sys' and 'game' are null or undefined
        // console.log('this.sys:', this.sys);
        // console.log('this.sys.game:', this.sys?.game);

        // Check if this.sys or this.sys.game is null before accessing config
        if (!this.sys || !this.sys.game || !this.sys.game.config) {
            console.error('Error: this.sys.game.config is null or undefined');
            return; // Exit the function early if null
        }

        const screenWidth = this.sys.game.config.width;
        const screenHeight = this.sys.game.config.height;

        // console.log('Screen Width:', screenWidth);
        // console.log('Screen Height:', screenHeight);

        // Create a full-screen transparent background for the overlay
        const overlayBackground = this.add.rectangle(
            screenWidth / 2, screenHeight / 2,
            screenWidth, screenHeight,
            0x000000
        ).setAlpha(0.8);  // Darken the background slightly (80% transparency)
        overlayBackground.setOrigin(0.5);

        // Display feedback text centered on the screen
        const feedbackDisplay = this.add.text(
            screenWidth / 2, screenHeight / 2,
            feedbackText,
            {
                fontFamily: 'Arial',
                fontSize: '24px',
                color: '#ffffff',
                wordWrap: { width: screenWidth * 0.8, useAdvancedWrap: true },
                align: 'center'
            }
        ).setOrigin(0.5);

        // Optionally, add a button to close the overlay after viewing the feedback
        const closeButton = this.add.rectangle(screenWidth / 2, screenHeight * 0.8, 150, 40, 0x007bff).setInteractive();
        const closeText = this.add.text(0, 0, 'Close', {
            fontFamily: 'Arial',
            fontSize: '18px',
            color: '#ffffff',
        }).setOrigin(0.5);
        Phaser.Display.Align.In.Center(closeText, closeButton);

        closeButton.on('pointerdown', () => {
            overlayBackground.destroy();
            feedbackDisplay.destroy();
            closeButton.destroy();
            closeText.destroy();
        });
    }




    clearGroup() {
        // Reset text area for the new question
        this.textArea.text = '';
        this.cursorPosition = 0;
        this.updateCursor(this.cursor);
    }


}

export default StoryFeedbackScene;
