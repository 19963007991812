import BaseScene from "./BaseScene"; // Adjust the path as necessary
import menuBackground from "../assets/backgrounds/MenuPage.jpg";
import backButton from "../assets/buttons/BackButton.png";
import ccButton from "../assets/buttons/CreativeCarnivalButton.png";
import ddButton from "../assets/buttons/DictationDesertButton.png";
import iiButton from "../assets/buttons/InterviewIslandButton.png";
import mmButton from "../assets/buttons/MathsMeadowButton.png";
import ppButton from "../assets/buttons/PaperPyramidButton.png";
import rrButton from "../assets/buttons/ReasoningRainforestButton.png";
import ssButton from "../assets/buttons/SpellingSafariButton.png";

class MenuScene extends BaseScene {
    constructor() {
        super('MenuScene');
    }


    init() {
        this.registry.set('debug', false);
        const isPortrait = this.sys.game.config.width < this.sys.game.config.height;
        if (isPortrait) {
            this.registry.set('isPortrait', true);
        }
    }

    preload() {
        this.load.image('menuBackgroundImage', menuBackground); // Note the key 'backgroundImage' here
        this.load.image('backButton', backButton);
        // Preload button images
        this.load.image('ccButton', ccButton);
        this.load.image('ddButton', ddButton);
        this.load.image('iiButton', iiButton);
        this.load.image('mmButton', mmButton);
        this.load.image('ppButton', ppButton);
        this.load.image('rrButton', rrButton);
        this.load.image('ssButton', ssButton);

    }

    create() {

        const debug = this.registry.get('debug');
        const isPortrait = this.registry.get('isPortrait');
        if (debug) {
            console.log('MenuScene is active.');
        }
        this.setupPageTemplate(isPortrait, debug, 'Topic Choise', 'menuBackgroundImage' , 'WelcomeScene');



        const userData = this.game.registry.get('userData');
        const strokeThickness = this.getStrokeThickness();
        const baseFontSize = this.getBaseFontSize();
        const textXPosition = this.sys.game.config.width * 0.33;
        const textYPosition = this.sys.game.config.height * 0.15;

        // Create the initial text
        const menuText = this.createAnimatedText(textXPosition, textYPosition, 'Where would you like to go?', {
            fontFamily: 'gaegu',
            fontSize: `${baseFontSize}px`,
            color: '#f78737',
            stroke: '#000000',
            strokeThickness: strokeThickness,
            align: 'center'
        });

        // List of scene names corresponding to buttons
        const sceneNames = ['CreativeCarnivalScene', 'DictationDesertScene', 'InterviewIslandScene', 'MathsMeadowScene', 'PaperPyramidScene', 'ReasoningRainforestScene', 'SpellingSafariScene'];


        // Layout parameters
        const column1X = this.sys.game.config.width * 0.33; // 33% for column 1
        const column2X = this.sys.game.config.width * 0.66; // 66% for column 2
        const startY = this.sys.game.config.height * 0.15; // Start Y position
        const buttonSpacing = this.sys.game.config.height * 0.15; // Space between rows

        // Calculate Y positions dynamically based on the text's position and spacing
        let nextYPosition = startY + this.sys.game.config.height * 0.2; // Adjust based on text size and desired padding

        // Example button creation, adjust indices and names as necessary
        const ccBut = this.createButton(column1X, nextYPosition, 'ccButton', null, () => this.scene.start('CreativeCarnivalScene'));
        // const ccBut = this.createButton(column1X, nextYPosition, 'ccButton', null, () => {
        //     window.dispatchEvent(new CustomEvent('navigateTo', { detail: { path: '/creativecarnival' } }));
        // });
        ccBut.on('pointerover', () => {
            menuText.setText('Where would you like to go? Creative Carnival');
        });
        // this.createButton(column2X, nextYPosition, 'ddButton', null, () => this.scene.start('DictationDesertScene'));
        const ddBut = this.createButton(column2X, nextYPosition, 'ddButton', null, () => {
            window.dispatchEvent(new CustomEvent('navigateTo', { detail: { path: '/dictationdesert' } }));
        });
        ddBut.on('pointerover', () => {
            menuText.setText('Where would you like to go? Dictation Desert');
        });

        nextYPosition += buttonSpacing; // Move to the next row
        // this.createButton(column1X, nextYPosition, 'iiButton', null, () => this.scene.start('InterviewIslandScene'));
        const iiBut = this.createButton(column1X, nextYPosition, 'iiButton', null, () => {
            window.dispatchEvent(new CustomEvent('navigateTo', { detail: { path: '/interviewisland' } }));
        });
        iiBut.on('pointerover', () => {
            menuText.setText('Where would you like to go? Interview Island');
        });
        const mmBut = this.createButton(column2X, nextYPosition, 'mmButton', null, () => this.scene.start('MathsMeadowScene'));
        mmBut.on('pointerover', () => {
            menuText.setText('Where would you like to go? Maths Meadow');
        });

        nextYPosition += buttonSpacing; // Move to the next row
        // this.createButton(column1X, nextYPosition, 'ppButton', null, () => this.scene.start('PaperPyramidScene'));
        const ppBut = this.createButton(column1X, nextYPosition, 'ppButton', null, () => {
            window.dispatchEvent(new CustomEvent('navigateTo', { detail: { path: '/paperpyramid' } }));
        });
        ppBut.on('pointerover', () => {
            menuText.setText('Where would you like to go? Paper Pyramid');
        });
        // this.createButton(column2X, nextYPosition, 'rrButton', null, () => this.scene.start('ReasoningRainforestScene'));
        const rrBut = this.createButton(column2X, nextYPosition, 'rrButton', null, () => {
            window.dispatchEvent(new CustomEvent('navigateTo', { detail: { path: '/reasoningrainforest' } }));
        });
        rrBut.on('pointerover', () => {
            menuText.setText('Where would you like to go? Reasoning Rainforest');
        });

        // Special case for the last button, centered
        // this.createButton(this.sys.game.config.width * 0.5, this.sys.game.config.height * 0.85, 'ssButton', null, () => this.scene.start('SpellingSafariScene'));
        const ssBut = this.createButton(this.sys.game.config.width * 0.5, this.sys.game.config.height * 0.85, 'ssButton', null, () => {
            window.dispatchEvent(new CustomEvent('navigateTo', { detail: { path: '/spellingsafari' } }));
        });
        ssBut.on('pointerover', () => {
            menuText.setText('Where would you like to go? Spelling Safari');
        });


    }



}

export default MenuScene;
